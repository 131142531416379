import React from 'react';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import Avatar from "Components/Widgets/Avatar";

const ContentItem = ({content, onClick}) => {
    function renderTime(time) {
        if(!isNaN(time)) {
            // video is VOD
            return (
                <div className={'content-time time'}>
                    <span>{time >= 60 ? moment.duration(time, 'seconds').format("hh:mm:ss") : ('00:' + time)}</span>
                </div>
            )
        } else if(time === 'live') {
            // video is LIVE
            return (
                <div className={'content-time live'}>
                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" className={'live-icon'}>
                        <g className="style-scope yt-icon">
                            <path
                                d="M16.94 6.91l-1.41 1.45c.9.94 1.46 2.22 1.46 3.64s-.56 2.71-1.46 3.64l1.41 1.45c1.27-1.31 2.05-3.11 2.05-5.09s-.78-3.79-2.05-5.09zM19.77
                                4l-1.41 1.45C19.98 7.13 21 9.44 21 12.01c0 2.57-1.01 4.88-2.64 6.54l1.4 1.45c2.01-2.04 3.24-4.87 3.24-7.99 0-3.13-1.23-5.96-3.23-8.01zM7.06
                                6.91c-1.27 1.3-2.05 3.1-2.05 5.09s.78 3.79 2.05 5.09l1.41-1.45c-.9-.94-1.46-2.22-1.46-3.64s.56-2.71 1.46-3.64L7.06 6.91zM5.64 5.45L4.24 4C2.23
                                6.04 1 8.87 1 11.99c0 3.13 1.23 5.96 3.23 8.01l1.41-1.45C4.02 16.87 3 14.56 3 11.99s1.01-4.88 2.64-6.54z"
                                className="style-scope yt-icon"/>
                            <circle cx="12" cy="12" r="3" className="style-scope yt-icon"/>
                        </g>
                    </svg>
                    <span>실시간</span>
                </div>
            )
        } else {
            return null;
        }
    }

    return (
        <div className={'video-item-box'}>
            <div className={'content-thumbnail'} onClick={onClick}>
                <div>
                    <img src={content.thumbnailLink ? content.thumbnailLink : `${process.env.PUBLIC_URL}/assets/images/img/no-image.png`} alt=''/>
                    {renderTime(content.videoTime)}
                    <div className={'content-mask'}/>
                </div>
            </div>
            <div className={'content-info-box'}>
                <Avatar
                    src={content.ownerUserInfo.userProfile}
                    name={content.ownerUserInfo.nickname}
                    className="rounded-circle border-info mr-20"
                />
                <div className={'content-info'}>
                    <div className={'content-name'}>{content.videoName}</div>
                    <a className={'content-owner'}>{content.ownerUserInfo.nickname}</a>
                    <div className={'content-visit-count'}>조회수 {content.visitCount}회 • {content.pastTime}전</div>
                </div>
            </div>
        </div>
    )
};

export default ContentItem;