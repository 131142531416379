import React from 'react';
import {Badge, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import Avatar from 'Components/Widgets/Avatar';
import {useDispatch, useSelector} from 'react-redux';
import {logoutUser,} from 'Store/Actions';
import * as Apis from "Api";

const UserBlock = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.authUser).userInfo;

    const onLogout = () => {
        Apis.logout().then(() => {
            dispatch(logoutUser());
        })
    }

    return (
        <UncontrolledDropdown nav className="list-inline-item header-user-block ml-3">
            <DropdownToggle nav className="p-0 d-flex align-items-center">
                <Avatar
                    alt="user"
                    size={30}
                    src={userInfo.profile}
                    name={userInfo.nickname ? userInfo.nickname : userInfo.username}
                    className="rounded-circle border-info mr-10"
                    style={{display: 'inline-flex'}}
                />
                <Button aria-label="bell" style={{textTransform: 'unset'}}>
                    {userInfo.nickname ? userInfo.nickname : userInfo.username}
                    <i className="zmdi zmdi-chevron-down ml-10"/>
                </Button>
            </DropdownToggle>
            <DropdownMenu right>
                <ul className="list-unstyled mb-0">
                    <li className="p-15 border-bottom user-profile-top bg-primary rounded-top">
                        <p className="text-white mb-0 fs-14">{userInfo.nickname ? userInfo.nickname : userInfo.username}</p>
                        {/*<span className="text-white fs-14">info@example.com</span>*/}
                    </li>
                    <li>
                        <Link to={{
                            pathname: '/channel/setting',
                            state: { activeTab: 0 }
                        }}>
                            <i className="zmdi zmdi-account text-primary mr-3"/>
                            <span>프로파일</span>
                        </Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to={{*/}
                    {/*        pathname: '/chat',*/}
                    {/*        state: { activeTab: 2 }*/}
                    {/*    }}>*/}
                    {/*        <i className="zmdi zmdi-comment-text-alt text-success mr-3"/>*/}
                    {/*        <span>메시지</span>*/}
                    {/*        <Badge color="danger" className="pull-right">3</Badge>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link to="/pages/feedback">*/}
                    {/*        <i className="zmdi zmdi-edit text-warning mr-3"/>*/}
                    {/*        <span>피드백</span>*/}
                    {/*        <Badge color="info" className="pull-right">1</Badge>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    <li className="border-top">
                        <a onClick={(e) => onLogout()}>
                            <i className="zmdi zmdi-power text-danger mr-3"/>
                            <span>로그아웃</span>
                        </a>
                    </li>
                </ul>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default UserBlock;