import React from "react";
import {useSelector} from "react-redux";
import {Redirect, Route} from "react-router-dom";

const PrivateRoute = ({component: Component, ...rest}) => {
    const authUser = useSelector(state => state.authUser);
    const {isLogin} = authUser;
    return (
        <Route
            {...rest}
            render={props =>
                isLogin
                    ? <Component {...props} />
                    : <Redirect
                        to={{
                            pathname: '/signin',
                            state: {from: props.location}
                        }}
                    />}
        />
    );
}

export default PrivateRoute;