/**
 * Message Block Component
 */
import React from 'react';
import moment from "moment";
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import Avatar from 'Components/Widgets/Avatar';

const Message = ({even, data, selectedUserPhotoUrl, showTimeStamp}) => {
    const OtherMessage = () => (
        <div className="d-flex flex-nowrap mb-3">
            <Avatar alt="user profile" src={selectedUserPhotoUrl} name={data.nickname} size={30} className="img-fluid rounded-circle mr-15 align-self-start"/>
            <div className="chat-bubble-wrap d-flex align-items-end">
                <div className="chat-bubble even bg-aqua">
                    <p className="mb-0 fs-12">{data.message}</p>
                </div>
                {
                    showTimeStamp && <span className="text-left d-block text-muted mt-1 fs-10">{moment(data.time).format('hh:mm A')}</span>
                }
            </div>
        </div>
    )

    const MyMessage = () => (
        <div className="d-flex flex-nowrap mb-3 flex-row-reverse">
            <Avatar alt="user profile" src={selectedUserPhotoUrl} name={data.nickname} size={30} className="img-fluid rounded-circle ml-15 align-self-start"/>
            <div className="chat-bubble-wrap d-flex align-items-end">
                {
                    showTimeStamp && <span className="text-right d-block text-muted mt-1 fs-10">{moment(data.time).format('hh:mm A')}</span>
                }
                <div className="chat-bubble odd bg-primary text-white">
                    <p className="mb-0 fs-12">{data.message}</p>
                </div>
            </div>
        </div>
    )

    const OtherPointMessage = () => (
        <div className="d-flex flex-row mb-3">
            <div className={'chat-point'}>
                <div className={'d-flex flex-row chat-point-header'}>
                    <Avatar alt="user profile" src={selectedUserPhotoUrl} name={data.nickname} className="img-fluid rounded-circle mr-15 align-self-start"/>
                    <div className={'d-flex flex-column justify-content-center'}>
                        <div>{data.nickname}</div>
                        <div>{data.point} 포인트</div>
                    </div>
                </div>
                <div className={'chat-point-body'}>
                    <p className="mb-0 fs-12">{data.message}</p>
                </div>
            </div>
        </div>
    )

    const MyPointMessage = () => (
        <div className="d-flex flex-row-reverse mb-3">
            <div className={'chat-point'}>
                <div className={'d-flex flex-row-reverse chat-point-header'}>
                    <Avatar alt="user profile" src={selectedUserPhotoUrl} name={data.nickname} className="img-fluid rounded-circle ml-15 align-self-start"/>
                    <div className={'d-flex flex-column justify-content-center'}>
                        <div>{data.nickname}</div>
                        <div>{data.point} 포인트</div>
                    </div>
                </div>
                <div className={'chat-point-body'}>
                    <p className="mb-0 fs-12">{data.message}</p>
                </div>
            </div>
        </div>
    )

    const PointErrorMessage = () => (
        <div className={'chat-point-error'}>
            <div>
                <span>포인트가 충분하지 않습니다.</span>
            </div>
            <ErrorOutlineRoundedIcon />
        </div>
    )

    if (data.type === 'chat') {
        if (even) {
            return <OtherMessage/>
        } else {
            return <MyMessage/>
        }
    } else if (data.type === 'point' && data.point) {
        if (data.point !== -1) {
            if (even) {
                return <OtherPointMessage/>
            } else {
                return <MyPointMessage/>
            }
        } else {
            // 포인트가 충분하지 않은경우
            return <PointErrorMessage/>
        }
    } else {
        return null;
    }
};

export default Message;
