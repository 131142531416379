import React from 'react'
import Select from 'react-select';
import SelectCreatable from 'react-select/creatable';
import {useSelector} from "react-redux";

const MySelect = (props) => {

    const settings = useSelector(state => state.settings);
    const { darkMode } = settings;
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: darkMode ? '#222' : 'white',
            border: darkMode ? '1px solid #ffffff33' : '1px solid #00000033'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: darkMode ? 'white' : 'black'
        }),
        multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: darkMode ? '#333' : '#777'
        }),
        multiValueLabel: (provider, state) => ({
            ...provider,
            color: 'white',
        }),
        menu: (provider, state) => ({
            ...provider,
            backgroundColor: darkMode ? '#222' : '#eee',
            border: '1px solid #ffffff33',
            borderRadius: 5,
        }),
        option: (provided, {data, isDisabled, isFocused, isSelected}) => {
            return {
                ...provided,
                backgroundColor: isDisabled ? '#999' : isSelected ? '#333' : isFocused ? '#555' : null,
            }
        },
    }
    const additionalProps = {
        styles: customStyles,
    }
    if (!props.isCreatable) {
        return (
            <Select
                {...props}
                {...additionalProps}
            />
        )
    } else {
        return (
            <SelectCreatable
                {...props}
                {...additionalProps}
            />
        )
    }
}

export default MySelect;