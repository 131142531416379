import React, {useState, useEffect} from "react";
import { Button } from '@material-ui/core';
import {Pagination} from "@material-ui/lab";
import {Input} from 'reactstrap';
import {useHistory} from "react-router-dom";
import * as Apis from 'Api';

const Community = () => {
    const history = useHistory();
    const [communityList, setCommunityList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchType, setSearchType] = useState('t');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(30);

    useEffect(() => {
    }, []);

    useEffect(() => {
        getData();
    }, [currentPage])

    const getData = () => {
        Apis.communityList(currentPage, searchType, searchText).then((resp) => {
            setCommunityList(resp.data);
            setCurrentPage(resp.meta.currentPage);
            setTotalPageCount(resp.meta.totalPageCount);
            setTotalItemCount(resp.meta.totalItemCount);
            setItemsPerPage(resp.meta.itemsPerPage);
        }).catch((e) => {

        });
    }

    return (
        <div className={'site-community'}>
            <h2 className={'site-community-title'}>커뮤니티</h2>
            <div className={'site-community-content'}>
                <div className={'site-community-list'}>
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th className={'text-center'}>No</th>
                            <th className={'text-center'}>제목</th>
                            <th className={'text-center'}>닉네임</th>
                            <th className={'text-center'}>등록일</th>
                            <th className={'text-center'}>조회</th>
                            <th className={'text-center'}>추천</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            communityList.map((v, i) => (
                                <tr key={v.id} onClick={() => history.push('/site/community/' + v.id)}>
                                    <td className={'text-center'}>{v.no}</td>
                                    <td className={'pl-2'}>{v.title}</td>
                                    <td className={'text-center'}>{v.nickname}</td>
                                    <td className={'text-center'}>{v.created_at}</td>
                                    <td className={'text-center'}>{v.visit}</td>
                                    <td className={'text-center'}>{v.vote}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                    <div className={'site-pagination'}>
                        <Pagination
                            className="m-3"
                            showFirstButton
                            showLastButton
                            color="primary"
                            siblingCount={3}
                            defaultPage={1}
                            count={totalPageCount}
                            onChange={(e, page) => setCurrentPage(page)}
                        />
                    </div>
                    <div className={'site-community-search-container'}>
                        <div className={'site-community-search-content'}>
                            <Input type={'select'} onChange={(e) => setSearchType(e.target.value)}>
                                <option value={'t'}>제목</option>
                                <option value={'tc'}>제목+내용</option>
                            </Input>
                            <Input
                                type={'text'}
                                placeholder={'검색어를 입력하세요'}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <i className={'zmdi zmdi-search'} />
                        </div>
                        <div>
                            <Button className={'site-community-search-btn'} onClick={() => history.push('/site/community/new')}>
                                글쓰기
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={'site-community-more'}>
                    <div className={'site-community-menu'}>
                        <div className={'site-community-menu-title'}>게시판</div>
                        <Button className={'site-community-menu-item'}>
                            <span>뉴스</span>
                            <i className={'zmdi zmdi-chevron-right'} />
                        </Button>
                        <Button className={'site-community-menu-item'}>
                            <span>분석</span>
                            <i className={'zmdi zmdi-chevron-right'} />
                        </Button>
                        <Button className={'site-community-menu-item active'}>
                            <span>커뮤니티</span>
                            <i className={'zmdi zmdi-chevron-down'} />
                        </Button>
                        <Button className={'site-community-menu-item'}>
                            <span>공지사항</span>
                            <i className={'zmdi zmdi-chevron-right'} />
                        </Button>
                    </div>
                    <div className={'site-community-top-container'}>
                        <div className={'site-community-top-header'}>
                            <div className={'site-community-top-title active'}>인기글</div>
                            <div className={'site-community-top-title'}>최신글</div>
                            <div className={'site-community-top-title'}>최신댓글</div>
                        </div>
                        <div className={'site-community-top-content'}>
                            <div className={'site-community-top-item'}>
                                <span>1위.</span> ISMS인증 가상자산산업
                            </div>
                            <div className={'site-community-top-item'}>
                                <span>2위.</span> 김프 살살 빠지네
                            </div>
                            <div className={'site-community-top-item'}>
                                <span>3위.</span> 자유게시판 이용 규칙
                            </div>
                            <div className={'site-community-top-item'}>
                                <span>4위.</span> 세계 3대 선물 거래소
                            </div>
                            <div className={'site-community-top-item'}>
                                <span>5위.</span> 폴리곤이 가져다줄 부가 두렵다!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Community;