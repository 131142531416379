/**
 * Blank Page
 */

import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import NotificationManager from "react-notifications";
import * as Apis from "Api";
import {signinUser,} from 'Store/Actions';


export default function SigninToken(props) {	
    const dispatch = useDispatch();
    const token = useParams().token;
    const onUserLoginToken = () => {
        if (token !== '') {
            Apis.logintoken(token).then((result) => {
                
                dispatch(signinUser(result.info, result.token, props.history));
            }).catch((e) => {
                NotificationManager.error('토큰이 정확하지 않습니다');
            }).finally(() => {
                
            });
        }
    }

    useEffect(() => {
        onUserLoginToken()
    }, []);



   return (
      <div className="blank-wrapper">
         
      </div>
   );
}
