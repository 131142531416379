import React from 'react';
import RctAppLayout from 'Components/RctAppLayout';
import {Redirect, Route, Switch} from 'react-router-dom';
import Library from './library';
import Playlist from './playlist';

const FeedRoute = ({match}) => (
    <RctAppLayout>
        <Switch>
            <Route path={`${match.url}/library`} component={Library}/>
            <Route path={`${match.url}/playlist`} component={Playlist}/>
        </Switch>
    </RctAppLayout>
);

export default FeedRoute;