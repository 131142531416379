import React, {useState, useEffect} from 'react';
import {IconButton, Button} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Slider from "react-slick";
import {MarketData, TickerTape} from "react-tradingview-embed";
import {useHistory} from 'react-router-dom';
import * as Apis from 'Api';
import {useSelector} from "react-redux";

const RootPage = () => {
    const history = useHistory();
    const settings = useSelector(state => state.settings);
    const [videoList, setVideoList] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        Apis.getVideoList().then((resp) => {
            setVideoList(resp);
        }).catch((e) => {
            console.error(e);
        })
    }

    const renderVideoItem = (item) => {
        return (
            <div key={item.id} className={'site-root-video'} style={{width: 170}} onClick={() => history.push('/view/' + item.id)}>
                <img src={item.thumbnailLink} alt=''/>
                <div>{item.videoName}</div>
            </div>
        )
    }

    return (
        <div className={'site-root'}>
            <div className={'site-root-hotclip'}>
                <div className={'d-flex justify-content-between mb-2'}>
                    <span>HOT CLIP</span>
                    <IconButton size='small' className={'site-root-video-morebtn'} onClick={() => history.push('/videos')}>
                        <AddIcon style={{color: 'black'}}/>
                    </IconButton>
                </div>
                <Slider
                    className="site-root-hotclip-videos"
                    infinite={false}
                    slidesToShow={1}
                    swipeToSlide
                    variableWidth={true}
                >
                    {
                        videoList.splice(0, 5).map((v) => renderVideoItem(v))
                    }
                </Slider>
            </div>
            <div className={'site-root-ads mt-30'}>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/img/site-banner1.png`} alt=''/>
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/img/site-banner2.png`} alt=''/>
                </div>
            </div>
            <div className={'site-root-links'}>
                <Button
                    className={'root-link-btn'}
                    startIcon={<img src={`${process.env.PUBLIC_URL}/assets/images/img/icon-news.png`} alt=''/>}
                    endIcon={<ArrowForwardIosIcon style={{fontSize: 12}}/>}
                    onClick={() => window.location.assign('https://bloomingbit.io/news')}
                >
                    뉴스
                </Button>
                <Button
                    className={'root-link-btn'}
                    startIcon={<img src={`${process.env.PUBLIC_URL}/assets/images/img/icon-chart.png`} alt=''/>}
                    endIcon={<ArrowForwardIosIcon style={{fontSize: 12}}/>}
                    onClick={() => history.push('/site/coin-chart')}
                >
                    시세 / 차트
                </Button>
                <Button
                    className={'root-link-btn'}
                    startIcon={<img src={`${process.env.PUBLIC_URL}/assets/images/img/icon-analyse.png`} alt=''/>}
                    endIcon={<ArrowForwardIosIcon style={{fontSize: 12}}/>}
                    onClick={() => history.push('/')}
                >
                    분석
                </Button>
                <Button
                    className={'root-link-btn'}
                    startIcon={<img src={`${process.env.PUBLIC_URL}/assets/images/img/icon-community.png`} alt=''/>}
                    endIcon={<ArrowForwardIosIcon style={{fontSize: 12}}/>}
                    onClick={() => history.push('/site/community')}
                >
                    커뮤니티
                </Button>
            </div>
            <div className={'site-root-market-info'}>
                <div className={'market-change'}>
                    <div className='market-change-title'>마켓 변동률</div>
                    <div className={'market-change-values'}>
                        <TickerTape
                            widgetProps={{
                                symbols: [
                                    {
                                        "proName": "BITSTAMP:BTCUSD",
                                        "title": "BTC/USD"
                                    },
                                    {
                                        "proName": "BITSTAMP:ETHUSD",
                                        "title": "ETH/USD"
                                    },
                                    {
                                        "proName": "NYSE:EOS",
                                        "title": "EOS/USD"
                                    },
                                    {
                                        "description": "",
                                        "proName": "BINANCE:BTCUSDT"
                                    },
                                    {
                                        "description": "",
                                        "proName": "BINANCE:SHIBUSDT"
                                    },
                                    {
                                        "description": "",
                                        "proName": "BINANCE:DOTUSDT"
                                    },
                                    {
                                        "description": "",
                                        "proName": "BINANCE:LINKUSDT"
                                    },
                                    {
                                        "description": "",
                                        "proName": "BINANCE:GRTUSDT"
                                    }
                                ],
                                showSymbolLogo: true,
                                colorTheme: settings.darkMode ? 'dark' : "light",
                                isTransparent: false,
                                displayMode: "compact",
                                "largeChartUrl": "https://ad-lib.tv/site/coin-chart",
                                locale: "kr"
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={'market-status'}>
                <div className={'market-status-title'}>시장현황</div>
                <div className={'market-status-data'}>
                    <MarketData
                        widgetProps={{
                            width: '800',
                            "symbolsGroups": [
                                {
                                    "name": "Indices",
                                    "originalName": "Indices",
                                    "symbols": [
                                        {
                                            "name": "BINANCE:BTCUSDT"
                                        },
                                        {
                                            "name": "BITSTAMP:BTCUSD"
                                        },
                                        {
                                            "name": "BINANCE:ETHUSDT"
                                        },
                                        {
                                            "name": "BINANCE:ADAUSDT"
                                        },
                                        {
                                            "name": "BITSTAMP:ETHUSD"
                                        }
                                    ]
                                }
                            ],
                            "showSymbolLogo": true,
                            "colorTheme":  settings.darkMode ? 'dark' : "light",
                            "largeChartUrl": "https://ad-lib.tv/site/coin-chart",
                            "isTransparent": false,
                            "locale": "kr",
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default RootPage;
