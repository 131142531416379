import React, {useState, useEffect} from "react";
import {Button} from "@material-ui/core";
import Avatar from "Components/Widgets/Avatar";
import {useHistory, useParams} from 'react-router-dom';
import {NotificationManager} from "react-notifications";
import HtmlToReact from "html-to-react"
import {useSelector} from "react-redux";
import * as Apis from 'Api';

const CommunityView = () => {
    const communityId = useParams().communityId;
    const history = useHistory();
    const htmlParser = new HtmlToReact.Parser()
    const isLogin = useSelector(state => state.authUser).isLogin;
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [newCommentText, setNewCommentText] = useState('');


    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        Apis.communityItemInfo(communityId).then((resp) => {
            setInfo(resp);
            setLoading(false);
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다');
        });
    }

    const onNewComment = () => {
        const content = newCommentText;
        setNewCommentText('');
        Apis.communityComment(communityId, content).then((resp) => {
            getData();
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다');
        });
    }

    const onCommunityVote = (type) => {
        Apis.communityVote(communityId, type).then((resp) => {
            getData();
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다');
        });
    }

    const renderUserCommentItem = (item) => {
        if(!item) {
            return null;
        } else {
            return (
                <a className={'d-flex justify-content-between pr-10 '} style={{width: '50%'}} href={'/site/community/' + item.id}>
                    <span>{item.title} <span className={'text-primary'}/></span>
                    <span className={'fs-9'}>{item.created_at}</span>
                </a>
            )
        }
    }

    if (loading) {
        return (
            <div className={'d-flex justify-content-center align-items-center mt-20'}>
                로딩중입니다...
            </div>
        )
    }

    return (
        <div className={'site-community'}>
            <h2 className={'site-community-title'}>커뮤니티</h2>
            <div className={'site-community-content'}>
                <div className={'site-community-data'}>
                    <div className={'site-community-edit-title-container'}>
                        <div className={'d-flex flex-row justify-content-between align-items-center'}>
                            <span className={'fs-14 ff-dream6'}>{info.title}</span>
                            <Button className={'site-community-report-btn'}>
                                <img src={require('Assets/img/icon_report.png').default} alt=''/>
                                <span className={'fs-10 ff-dream4'}>신고</span>
                            </Button>
                        </div>
                        <div className={'site-community-info'}>
                            <div className={'d-flex flex-row'}>
                                <Avatar alt="user profile" src={info.userInfo.profile} name={info.userInfo.nickname} size={18} className="img-fluid rounded-circle mr-1 align-self-start"/>
                                <span>{info.userInfo.nickname}</span>
                                <img src={require('Assets/img/icon_follow.png').default} alt='' className={'icon-follow ml-3 mr-1'}/>
                                <span>팔로우</span>
                            </div>
                            <div>
                                <span>작성 {info.created_at}</span>
                                <span className={'ml-2 mr-2'}>|</span>
                                <span>조회 {info.visit}</span>
                                <span className={'ml-2 mr-2'}>|</span>
                                <span>공감 {info.voteUp}</span>
                                <span className={'ml-2 mr-2'}>|</span>
                                <span>비공감 {info.voteDown}</span>
                            </div>
                        </div>
                    </div>
                    <div className={'site-community-data-content'}>
                        {htmlParser.parse(info.content)}
                    </div>
                    {
                        isLogin ?
                            <div className={'d-flex flex-row justify-content-center mt-30'}>
                                <Button className={'site-community-view-vote ' + (info.voteStatus ? 'active-up' : '')} onClick={() => onCommunityVote(true)}>
                                    <span>공감 {info.voteUp}</span>
                                    <i className={'zmdi zmdi-thumb-up'}/>
                                </Button>
                                <Button className={'site-community-view-vote ' + (info.voteStatus === false ? 'active-down' : '')} onClick={() => onCommunityVote(false)}>
                                    <i className={'zmdi zmdi-thumb-down mr-1'}/>
                                    <span>비공감 {info.voteDown}</span>
                                </Button>
                            </div> :
                            <div />
                    }
                    <div className={'site-community-view-userdata mt-30'}>
                        <div className={'community-view-user-info'}>
                            <div>
                                <Avatar alt="user profile" src={info.userInfo.profile} name={info.userInfo.nickname} size={55} className="img-fluid rounded-circle mr-1 align-self-start"/>
                            </div>
                            <div className={'d-flex flex-column col pr-0'}>
                                <div className={'d-flex flex-row justify-content-between align-items-center'}>
                                    <span className={'fs-14 ff-dream6'}>{info.userInfo.nickname}</span>
                                    <div className={'d-flex flex-row'}>
                                        <Button className={'p-0 m-0 mr-2'}>
                                            <span className={'fs-11 ff-dream6 mr-1'}>팔로우</span>
                                            <i className="zmdi zmdi-plus-circle fs-14 text-orange"/>
                                        </Button>
                                        <Button className={'p-0 m-0'}>
                                            <span className={'fs-11 ff-dream6 mr-1'}>쪽지</span>
                                            <i className="zmdi zmdi-email fs-14 text-orange"/>
                                        </Button>
                                    </div>
                                </div>
                                <div className={'d-flex flex-row site-community-view-statistic'}>
                                    <div className={'flex-fill d-flex flex-column align-items-center'}>
                                        <span>응원랭킹</span>
                                        <span>1위</span>
                                    </div>
                                    <div className={'flex-fill d-flex flex-column align-items-center'}>
                                        <span>응원적중/회수</span>
                                        <span>5 / 10</span>
                                    </div>
                                    <div className={'flex-fill d-flex flex-column align-items-center'}>
                                        <span>응원적중률</span>
                                        <span>50%</span>
                                    </div>
                                    <div className={'flex-fill d-flex flex-column align-items-center'}>
                                        <span>공감랭킹</span>
                                        <span>3위</span>
                                    </div>
                                    <div className={'flex-fill d-flex flex-column align-items-center'}>
                                        <span>공감 / 비공감</span>
                                        <span>25 / 5</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'site-community-user-posts'}>
                            <div className={'d-flex flex-row justify-content-between align-items-center mb-20'}>
                                <span className={'fs-12 ff-dream6 '}>{info.userInfo.nickname}님의 다른글</span>
                                <Button className={'site-community-view-all-btn'}>
                                    <span className={'fs-10 ff-dream4 mr-1'}>{info.userInfo.nickname}님의 글 모두보기</span>
                                </Button>
                            </div>
                            <div className={'d-flex flex-row fs-11 mt-1 mb-1'}>
                                {renderUserCommentItem(info.userCommentList[0])}
                                {renderUserCommentItem(info.userCommentList[1])}
                            </div>
                            <div className={'d-flex flex-row fs-11 mt-1 mb-1'}>
                                {renderUserCommentItem(info.userCommentList[2])}
                                {renderUserCommentItem(info.userCommentList[3])}
                            </div>
                        </div>
                    </div>
                    <div className={'site-community-comment-container mt-30 mb-30'}>
                        <div className={'fs-15 ff-dream6 site-user-comment-list-title'}>
                            댓글 ({info.commentList ? info.commentList.length : 0}개)
                        </div>
                        {
                            info.commentList && info.commentList.map((v) => (
                                <div className={'site-user-comment-list d-flex flex-row'} key={v.id}>
                                    <Avatar alt="user profile" src={v.profile} name={v.nickname} size={35} className="img-fluid rounded-circle mr-1 align-self-start"/>
                                    <div className={'flex-fill d-flex flex-column fs-10 ml-10'}>
                                        <div>{v.nickname} <span className={'ml-10 mr-10'}>|</span> {v.created_at}</div>
                                        <div className={'fs-12 ff-dream5'}>{v.commentContent}</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className={'site-community-comment-input'}>
                        <div className={'d-flex flex-row justify-content-between mb-10'}>
                            <div className={'ff-dream6 fs-14'}>댓글작성 <span className={'fs-11 ff-dream4'}>(0 / 300 byte )</span></div>
                            <div className={'d-flex flex-row align-items-center'}>
                                <input type={'checkbox'}/>
                                <span className={'fs-11 ff-dream6 ml-1'}>비밀글</span>
                                <span className={'fs-11'}>(체크하시면 운영자와 작성자만 볼수 있습니다)</span>
                            </div>
                        </div>
                        <div>
                            <textarea
                                value={newCommentText}
                                onChange={(e) => setNewCommentText(e.target.value)}
                            />
                        </div>
                        <div className={'d-flex flex-row justify-content-end'}>
                            <Button className={'site-community-comment-submit mt-1'}>
                                <span className={'fs-11 ff-dream6 text-white'} onClick={onNewComment}>등록</span>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={'site-community-more'}>
                    <div className={'site-community-menu'}>
                        <div className={'site-community-menu-title'}>게시판</div>
                        <Button className={'site-community-menu-item'}>
                            <span>뉴스</span>
                            <i className={'zmdi zmdi-chevron-right'}/>
                        </Button>
                        <Button className={'site-community-menu-item'}>
                            <span>분석</span>
                            <i className={'zmdi zmdi-chevron-right'}/>
                        </Button>
                        <Button className={'site-community-menu-item active'}>
                            <span>커뮤니티</span>
                            <i className={'zmdi zmdi-chevron-down'}/>
                        </Button>
                        <Button className={'site-community-menu-item'}>
                            <span>공지사항</span>
                            <i className={'zmdi zmdi-chevron-right'}/>
                        </Button>
                    </div>
                    <div className={'site-community-top-container'}>
                        <div className={'site-community-top-header'}>
                            <div className={'site-community-top-title active'}>인기글</div>
                            <div className={'site-community-top-title'}>최신글</div>
                            <div className={'site-community-top-title'}>최신댓글</div>
                        </div>
                        <div className={'site-community-top-content'}>
                            <div className={'site-community-top-item'}>
                                <span>1위.</span> ISMS인증 가상자산산업
                            </div>
                            <div className={'site-community-top-item'}>
                                <span>2위.</span> 김프 살살 빠지네
                            </div>
                            <div className={'site-community-top-item'}>
                                <span>3위.</span> 자유게시판 이용 규칙
                            </div>
                            <div className={'site-community-top-item'}>
                                <span>4위.</span> 세계 3대 선물 거래소
                            </div>
                            <div className={'site-community-top-item'}>
                                <span>5위.</span> 폴리곤이 가져다줄 부가 두렵다!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommunityView;