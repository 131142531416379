import React from 'react';
import {AdvancedChart} from "react-tradingview-embed";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

const TradingChart = React.memo(() => {
    const query = new URLSearchParams(useLocation().search);
    const settings = useSelector(state => state.settings);
    return (
        <div className={'site-trading-chart'}>
            <div className={'loading-txt'}>로딩중...</div>
            <AdvancedChart
                widgetProps={{
                    symbol: query.get('tvwidgetsymbol') || 'BINANCE:BTCUSDT',
                    width: '100%',
                    height: 400,
                    style: '1',
                    theme: settings.darkMode ? 'dark' : "light",
                    allow_symbol_change: true,
                    locale: 'kr'
                }}
            />
        </div>
    )
});

export default TradingChart;