import React, {useState, useEffect} from 'react';
import RctAppLayout from 'Components/RctAppLayout';
import {
    Player, ControlBar, ReplayControl,
    ForwardControl, CurrentTimeDisplay,
    TimeDivider, PlaybackRateMenuButton, VolumeMenuButton, BigPlayButton
} from 'video-react';
import {
    FormGroup,
    Label,
    Input,
    FormText,
} from 'reactstrap';
import FlvPlayer from '../view/FlvPlayerNode.js';
//import FlvPlayer from '../view/FlvPlayer.js';
import {Button} from "@material-ui/core";
import {Scrollbars} from 'react-custom-scrollbars';
import {NotificationManager} from "react-notifications";
import urlJoin from 'url-join';
import {useDispatch, useSelector} from 'react-redux';
import {collapsedSidebarAction} from 'Store/Actions';
import MySelect from './component/MySelect';
import ChatComponent from '../view/components/ChatComponent';
import * as Apis from 'Api';

const Live = () => {
    const dispatch = useDispatch();
    const hiddenFileInput = React.useRef();
    const [state, setState] = useState({
        liveId: null,
        name: '',
        categoryId: '',
        desc: '',
        tags: [],
        thumbnail: '',
        streamKey: '',
        streamServer: '',
        playPath: '',
        isOnline: false,
        categories: [],
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(collapsedSidebarAction(true));
        getData();
    }, []);

    const getData = () => {
        Promise.all([
            Apis.getUserLiveInfo(),
            Apis.getCategories(),
        ]).then(([liveInfo, categories]) => {
            const info = liveInfo.info;
            setState({
                liveId: liveInfo.liveId,
                name: info.live_info_name,
                categoryId: info.live_info_category_id,
                desc: info.live_info_desc || '',
                tags: info.live_info_tags ? info.live_info_tags.split(',') : [],
                thumbnail: info.live_info_thumbnail,
                streamKey: info.live_info_stream_key,
                streamServer: liveInfo.streamServer,
                playPath: liveInfo.playPath,
                isOnline: liveInfo.isOnline,
                categories: categories.map((v) => ({value: v.id, label: v.category_name})),
            });
            setLoading(false);
        }).catch((e) => {
            console.log(e)
        });
    }

    const changeThumbnail = event => {
        hiddenFileInput.current.click();
    };

    const handleChangeThumbnail = event => {
        const thumbnailFile = event.target.files[0];
        const formData = new FormData();
        formData.append('thumbnail', thumbnailFile);
        Apis.updateUserLiveThumbnail(formData).then((resp) => {
            setState({
                ...state,
                thumbnail: resp.thumbnail
            })
        }).catch((e) => {

        });
    };

    const onChangeValue = (key, value) => {
        setState({
            ...state,
            [key]: value
        });
    }

    const onUpdateUserLiveInfo = () => {
        const data = {
            name: state.name,
            categoryId: state.categoryId,
            desc: state.desc,
            tags: state.tags.join(',')
        }
        Apis.updateUserLiveInfo(data).then((resp) => {
            NotificationManager.success('정보가 변경되였습니다');
        }).catch((e) => {
            NotificationManager.error('업데이트중 오류가 발생했습니다');
        })
    }

    const onStartLive = () => {
        Apis.startLive().then((resp) => {
            setState({
                ...state,
                liveId: resp,
                isOnline: true
            })
        }).catch((e) => {
            NotificationManager.error('라이브시작중 오류가 발생했습니다. 모든 정보를 정확히 입력하세요.');
        });
    }

    const onStopLive = () => {
        Apis.stopLive().then((resp) => {
            setState({
                ...state,
                isOnline: false
            });
            getData();
        }).catch((e) => {
            NotificationManager.error('라이브중지중 오류가 발생했습니다');
        });
    }

    return (
        <RctAppLayout>
        <div className={'view-container'}>
            <Scrollbars
                className="video-wrapper"
                autoHide
                autoHideDuration={100}
            >
                <div className={'video-block'}>
                    <div className={'d-flex'}>
                        {
                            state.playPath && state.playPath.length > 0 &&
                            <FlvPlayer
                                poster={state.thumbnail ? state.thumbnail : `${process.env.PUBLIC_URL}/assets/images/img/no-image.png`}
                                src={state.playPath}
                            />
                        }

                        {/*<Player*/}
                        {/*    poster={state.thumbnail ? state.thumbnail : `${process.env.PUBLIC_URL}/assets/images/img/no-image.png`}*/}
                        {/*>*/}
                        {/*    <source src={state.playPath}/>*/}
                        {/*    <BigPlayButton position="center"/>*/}
                        {/*    <ControlBar>*/}
                        {/*        <CurrentTimeDisplay order={4.1}/>*/}
                        {/*        <TimeDivider order={4.2}/>*/}
                        {/*        <VolumeMenuButton/>*/}
                        {/*    </ControlBar>*/}
                        {/*</Player>*/}
                        <div className={'live-info-box d-flex flex-column'}>
                            <p>방송정보</p>
                            <div style={{flex: 1}} className={'mx-2'}>
                                <div className={'fs-13 mb-3'}>상태: 방송전</div>
                                <div className={'fs-13 mb-3'}>방송시작시간:</div>
                                <div className={'fs-13 mb-3'}>방송시간:</div>
                                <div className={'fs-13 mb-3'}>현재시청자:</div>
                                <div className={'fs-13 mb-3'}>누적신청자:</div>
                            </div>
                            <div className={'d-flex justify-content-center mb-3'}>
                                {
                                    !state.isOnline ?
                                        <Button variant="contained" onClick={onStartLive} className="btn-xs bg-primary text-white">
                                            방송시작
                                        </Button> :
                                        <Button variant="contained" onClick={onStopLive} className="btn-xs bg-danger text-white">
                                            방송중지
                                        </Button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'split-line my-20'}/>
                    <div className={'live-meta-block'}>
                        <div className={'col-0 mx-20'} style={{flex: 1}}>
                            <p className={'fs-17 fw-bold'}>기본정보</p>
                            <FormGroup>
                                <Label for="title">제목</Label>
                                <Input
                                    type="text" name="title" placeholder="제목을 입력하세요" value={state.name}
                                    onChange={(e) => onChangeValue('name', e.target.value)}
                                />
                            </FormGroup>
                            <div className={'d-flex'}>
                                <FormGroup style={{flex: 2}}>
                                    <Label for="category">카테고리</Label>
                                    <MySelect
                                        className={'live-info-select'}
                                        value={state.categories.find((v) => v.value === state.categoryId)}
                                        isClearable
                                        isSearchable
                                        noOptionsMessage={() => <span className={'fs-12'}>항목이 존재하지 않습니다.</span>}
                                        placeholder={'카테고리선택'}
                                        name='category'
                                        options={state.categories}
                                        onChange={(value) => onChangeValue('categoryId', value.value)}
                                    />
                                </FormGroup>
                                <div style={{marginTop: 29}} className={'ml-40'}>
                                    <Button onClick={changeThumbnail} variant="outlined" color="primary" className={'btn-thumbnail'}>
                                        썸네일변경
                                    </Button>
                                    <input
                                        type="file"
                                        ref={hiddenFileInput}
                                        onChange={handleChangeThumbnail}
                                        accept={'.jpg, .jpeg, .png, '}
                                        style={{display: 'none'}}
                                    />
                                </div>
                            </div>
                            <FormGroup>
                                <Label for="desc">설명</Label>
                                <Input type="textarea" name="desc" value={state.desc} onChange={(e) => onChangeValue('desc', e.target.value)}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleUrl">태그</Label>
                                <MySelect
                                    className={'live-info-select'}
                                    value={state.tags.map((v) => ({value: v, label: v}))}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    isCreatable
                                    noOptionsMessage={() => null}
                                    formatCreateLabel={(inputValue) => <span className={'fs-12'}>{inputValue} 추가</span>}
                                    placeholder={'태그입력'}
                                    name='tag'
                                    options={[]}
                                    onChange={(value) => onChangeValue('tags', value.map((v) => v.value))}
                                />
                            </FormGroup>
                            <div className={'d-flex justify-content-center'}>
                                <Button variant="contained" className="bg-primary text-white pl-40 pr-40" onClick={onUpdateUserLiveInfo}>
                                    업데이트
                                </Button>
                            </div>

                        </div>
                        <div className={'split-vertical-line mx-10 mt-30'}/>
                        <div className={'d-flex flex-column mx-20'} style={{flex: 1}}>
                            <p className={'fs-17 fw-bold'}>스트림설정</p>
                            <FormGroup>
                                <Label>스트림키</Label>
                                <Input type="text" value={state.streamKey} disabled/>
                            </FormGroup>
                            <FormGroup>
                                <Label>스트림 URL</Label>
                                <Input type="text" value={state.streamServer} disabled/>
                            </FormGroup>
                        </div>
                    </div>
                </div>
            </Scrollbars>
            {
                (!loading && state.liveId) && <ChatComponent liveId={state.liveId}/>
            }
        </div>
        </RctAppLayout>
    )
};

export default Live;