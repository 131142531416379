import React, {useEffect, useState} from 'react'
import {Helmet} from "react-helmet";
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';
import {FormGroup, Col, Input, Label} from "reactstrap";
import {collapsedSidebarAction} from "Store/Actions";
import {useDispatch} from "react-redux";
import {Button} from "@material-ui/core";
import Avatar from 'Components/Widgets/Avatar';
import * as Apis from 'Api';

const Setting = () => {
    const dispatch = useDispatch();
    const hiddenFileInput = React.useRef();
    const [nickname, setNickname] = useState('');
    const [profile, setProfile] = useState('');


    useEffect(() => {
        dispatch(collapsedSidebarAction(false));
        getData();
    }, []);

    const getData = () => {
        console.log('start')
        Apis.getUserInfo().then((resp) => {
            setNickname(resp.nickname);
            setProfile(resp.profile);
        }).catch((e) => {
        }).finally(() => {
        });
    }

    const handleChangeProfile = event => {
        const profileFile = event.target.files[0];
        setProfile(profileFile);
    };

    const onChangeProfile = event => {
        hiddenFileInput.current.click();
    };

    const onUpdateInfo = () => {
        Apis.setUserInfo(nickname, typeof profile === 'object' ? profile : undefined).then((resp) => {

        }).catch((e) => {

        });
    }

    return (
        <div className={'p-10 profile-block'}>
            <Helmet>
                <title>사용자정보</title>
                <meta name="description" content="user setting"/>
            </Helmet>
            <PageTitleBar title={"사용자정보"}/>
            <RctCollapsibleCard contentCustomClasses={'p-40 d-flex'}>
                <Col sm={10} md={8} lg={6}>
                    <FormGroup row>
                        <Label for="title" sm={2}>닉네임</Label>
                        <Col sm={10}>
                            <Input
                                type="text" name="title" placeholder="닉네임을 입력하세요" value={nickname}
                                onChange={(e) => setNickname(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="title" sm={2}>프로파일</Label>
                        <Col sm={10} className={'d-flex'}>
                            <Avatar
                                alt="user profile"
                                size={80}
                                src={typeof profile === 'object' ? URL.createObjectURL(profile) : profile}
                                className="profile-img"
                            />
                            <div className={'d-flex align-items-center'}>
                                <Button onClick={onChangeProfile} variant="contained" color="secondary" size={'small'} className={'mt-40'}>
                                    파일선택
                                </Button>
                                <input
                                    type="file"
                                    ref={hiddenFileInput}
                                    onChange={handleChangeProfile}
                                    accept={'.jpg, .jpeg, .png, '}
                                    style={{display: 'none'}}
                                />
                            </div>
                        </Col>
                    </FormGroup>
                    <div className={'d-flex justify-content-center mt-40'}>
                        <Button onClick={onUpdateInfo} className="btn-primary text-white">
                            보관
                        </Button>
                    </div>
                </Col>
            </RctCollapsibleCard>

        </div>
    )
}

export default Setting;