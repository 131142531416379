/**
 * Site Routes
 */
import React from 'react';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import RctSiteLayout from "Components/RctSiteLayout";
import PrivateRoute from 'Container/PrivateRoute';
import RootPage from './root';
import Community from "./community";
import CommunityView from "./community/view";
import CommunityNew from "./community/new";
import CoinChart from './coin-chart';


function SiteRoute(props) {
    const {match} = props;
    return (
        <RctSiteLayout>
            <Switch>
                <PrivateRoute path={`${match.url}/community/new`} component={CommunityNew}/>
                <Route path={`${match.url}/community/:communityId`} component={CommunityView}/>
                <Route path={`${match.url}/community`} component={Community}/>

                <Route path={`${match.url}/coin-chart`} component={CoinChart}/>
                <Route path={`${match.url}/`} component={RootPage}/>
            </Switch>
        </RctSiteLayout>
    );
}

export default withRouter(SiteRoute);
