import React, {useEffect, useState} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {IconButton, Menu, MenuItem} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {NotificationManager} from "react-notifications";
import moment from "moment";
import * as Apis from 'Api';


const Playlist = () => {
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);
    const title = {hl: '시청기록', wl: '나중에 볼 동영상', ll: '좋아요 표시한 동영상'};
    const [listType, setListType] = useState(query.get('list'));
    const [videoList, setVideoList] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuSelectedId, setMenuSelectedId] = useState();

    useEffect(() => {
        if (query.get('list') !== listType) {
            setListType(query.get('list'));
        }
    })

    useEffect(() => {
        getData();
    }, [listType]);

    const getData = () => {
        let apiFunc;
        if (listType === 'hl') {
            apiFunc = 'getHistory';
        } else if (listType === 'wl') {
            apiFunc = 'getPlaylist';
        } else if (listType === 'll') {
            apiFunc = 'getVideoLikeList';
        }
        if (apiFunc) {
            Apis[apiFunc]().then((resp) => {
                setVideoList(resp);
            }).catch((e) => {
                NotificationManager.error('오류가 발생했습니다');
            })
        }
    }

    const onOpenItemMenu = (event, itemId) => {
        setAnchorEl(event.currentTarget);
        setMenuSelectedId(itemId);
    };

    const onDeleteItem = () => {
        setAnchorEl(null);
        let apiFunc;
        if (listType === 'hl') {
            apiFunc = 'deleteHistory';
        } else if (listType === 'wl') {
            apiFunc = 'deletePlaylist';
        } else if (listType === 'll') {
            apiFunc = 'deleteVideoLike';
        }
        if (apiFunc) {
            Apis[apiFunc](menuSelectedId).then((resp) => {
                getData();
            }).catch((e) => {
                NotificationManager.error('오류가 발생했습니다');
            })
        }
    };

    const renderItem = (item) => {
        return (
            <div key={item.id} className={'playlist-item'} onClick={() => history.push('/view/' + item.id)}>
                <div className={'d-flex flex-row'}>
                    <div className={'playlist-item-img'}>
                        <img src={item.thumbnailLink ? item.thumbnailLink : `${process.env.PUBLIC_URL}/assets/images/img/no-image.png`} alt='item-img'/>
                        <div className={'playlist-item-time'}>
                            <span>{item.videoTime >= 60 ? moment.duration(item.videoTime, 'seconds').format("hh:mm:ss") : ('00:' + item.videoTime)}</span>
                        </div>
                    </div>
                    <div className={'playlist-item-content'}>
                        <div className={'playlist-item-title'}>
                            {item.videoName}
                        </div>
                        <div className={'playlist-item-username'}>
                            {item.ownerUserInfo.nickname} • 조회수 {item.visitCount}회
                        </div>
                    </div>
                </div>
                <div className={'align-items-center playlist-item-more'}>
                    <IconButton onClick={(e) => onOpenItemMenu(e, item.id)}>
                        <MoreVertIcon />
                    </IconButton>
                </div>
            </div>
        )
    }

    return (
        <div className={'playlist-container'}>
            <div className={'playlist-content'}>
                <span className={'pb-3'}>{title[listType]}</span>
                {videoList.map((v) => renderItem(v))}
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={onDeleteItem}>삭제</MenuItem>
            </Menu>
        </div>
    )
}

export default Playlist;