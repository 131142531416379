import React, {useEffect, useCallback, useState} from 'react';
import {Button, Dialog, DialogContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone'
import CustomDialogTitle from "Components/Dialog/CustomDialogTitle";
import ProgressRing from "Components/ProgressRing";
import * as Apis from 'Api';
import {FormGroup, Input, Label} from "reactstrap";
import MySelect from "../../live/component/MySelect";
import {NotificationManager} from "react-notifications";

const VodUploadModal = ({show, vodId, onClose, onFinish}) => {
    const hiddenFileInput = React.useRef();
    const [errorFile, setErrorFile] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [formType, setFormType] = useState('UPLOAD_FORM');
    const [vodInfo, setVodInfo] = useState({
        id: '',
        name: '',
        categoryId: '',
        desc: '',
        tags: [],
        thumbnail: ''
    });
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        if(vodId !== '') {
            setVodInfo({
                ...vodInfo,
                id: vodId
            });
            setFormType('INFO_FORM')
            getData(vodId);
        }
        getCategories();
    }, []);

    const getCategories = () => {
        Apis.getCategories().then((categories) => {
            setCategoryList(categories.map((v) => ({value: v.id, label: v.category_name})));
        }).catch((e) => {
            console.log(e)
        });
    }

    const getData = (vodId) => {
        if(!vodId) {
            vodId = vodInfo.id;
        }
        if(vodId === '') return;
        Apis.getMyVodInfo(vodId).then((resp) => {
            setVodInfo({
                id: resp.id,
                name: resp.name,
                categoryId: resp.categoryId,
                desc: resp.desc,
                tags: resp.tags ? resp.tags.split(',') : [],
                thumbnail: resp.thumbnail,
            })
        }).catch((e) => {

        });
    }

    const changeThumbnail = event => {
        hiddenFileInput.current.click();
    };

    const handleChangeThumbnail = event => {
        const thumbnailFile = event.target.files[0];
        const formData = new FormData();
        formData.append('thumbnail', thumbnailFile);
        Apis.updateVodThumbnail(vodInfo.id, formData).then((resp) => {
            setVodInfo({
                ...vodInfo,
                thumbnail: resp.thumbnail
            });
        }).catch((e) => {

        });
    };

    const onChangeVodInfo = (key, value) => {
        setVodInfo({
            ...vodInfo,
            [key]: value
        });
    }

    const onUploadProgress = (progress) => {
        setUploadProgress(progress)
    }

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        if (acceptedFiles.length === 0) {
            setErrorFile(true);
            return;
        }
        setErrorFile(false);
        const formData = new FormData();
        formData.append(acceptedFiles[0].name.split('.')[0], acceptedFiles[0]);
        // acceptedFiles.forEach((v) => {
        //     formData.append(v.name.split('.')[0], v);
        // });

        Apis.uploadVods(formData, onUploadProgress).then((resp) => {
            setVodInfo({
                id: resp.id,
                name: resp.name,
                categoryId: resp.categoryId,
                desc: resp.desc,
                tags: resp.tags ? resp.tags.split(',') : [],
                thumbnail: resp.thumbnail,
            });
            setFormType('INFO_FORM');
        }).catch((e) => {
            setUploadProgress(0);
            NotificationManager.error('동영상업로드중 오류가 발생하였습니다.');
        });
        console.log('drop file', acceptedFiles)
    }, []);

    const {
        getRootProps, getInputProps, isDragActive, open, isDragReject
    } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        accept: 'video/*'
    });

    const onCloseDialog = () => {
        // check uploading file
        if (uploadProgress === 0 || uploadProgress === 100) {
            onClose();
        }
    }

    const onSaveVodInfo = () => {
        Apis.updateVodInfo(vodInfo.id, vodInfo).then((resp) => {
            onClose()

        }).catch((e) => {
            NotificationManager.error('업데이트중 오류가 발생했습니다.');
            onClose()
        })
    }

    const renderDropZoneMsg = () => (
        <div className={'d-flex flex-column align-items-center'}>
            <div className={'mb-3'}>
                동영상 파일을 드래그 앤 드롭하여 업로드
            </div>
            {
                isDragReject &&
                <div className={'vod-file-error'}>
                    업로드할수 없는 형식의 파일입니다.
                </div>
            }
            {
                errorFile &&
                <div className={'vod-file-error'}>
                    파일형식이 잘못되었습니다
                </div>
            }
            <Button onClick={open} className="btn-primary text-white">
                파일선택
            </Button>
        </div>
    );

    const renderUploadState = () => (
        <div className={'d-flex flex-column align-items-center'}>
            <div className={'mb-2'}>파일을 업로드중입니다. 잠시 기다려주세요</div>
            <div>{uploadProgress !== 100 ? uploadProgress + '%' : '파일처리중.....'}</div>
        </div>
    )

    const renderDropZone = () => (
        <div className={'d-flex flex-column flex-grow-1'}>
            <div {...getRootProps()} className={'vod-modal-content vod-drop-box ' + (isDragActive ? 'drag-active' : '')}>
                <input {...getInputProps()} />
                <div className={'drop-upload-icon-container'}>
                    <i className={'zmdi zmdi-cloud-upload upload-icon'}/>
                    {
                        uploadProgress !== 0 &&
                        <div className={'vod-upload-progress'}>
                            <ProgressRing radius={80} stroke={4} progress={uploadProgress}/>
                        </div>
                    }
                </div>
                {
                    uploadProgress === 0 ? renderDropZoneMsg() : renderUploadState()
                }
            </div>

            <div className={'d-flex flex-column align-items-center pb-40 fs-13'}>
                <span>사이트에 동영상을 제출하면 서비스약관 및 커뮤니티 가이드에 동의하게 됩니다</span>
                <span>타인의 저작권 또는 개인정보 보호 권한을 침해해서는 안됩니다.</span>
            </div>
        </div>
    );

    const renderVideoInfo = () => (
        <div className={'mt-50'}>
            <div className={'row p-20'}>
                <div className={'col-sm-7'}>
                    <FormGroup>
                        <Label for="title">제목</Label>
                        <Input
                            type="text" name="title" placeholder="제목을 입력하세요" value={vodInfo.name}
                            onChange={(e) => onChangeVodInfo('name', e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup style={{flex: 2}}>
                        <Label for="category">카테고리</Label>
                        <MySelect
                            className={'live-info-select'}
                            value={categoryList.find((v) => v.value === vodInfo.categoryId)}
                            isClearable
                            isSearchable
                            noOptionsMessage={() => <span className={'fs-12'}>항목이 존재하지 않습니다.</span>}
                            placeholder={'카테고리선택'}
                            name='category'
                            options={categoryList}
                            onChange={(value) => onChangeVodInfo('categoryId', value.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="desc">설명</Label>
                        <Input type="textarea" name="desc" value={vodInfo.desc} onChange={(e) => onChangeVodInfo('desc', e.target.value)} style={{height: 150}}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="exampleUrl">태그</Label>
                        <MySelect
                            className={'live-info-select'}
                            value={vodInfo.tags.map((v) => ({value: v, label: v}))}
                            isClearable
                            isSearchable
                            isMulti
                            isCreatable
                            noOptionsMessage={() => null}
                            formatCreateLabel={(inputValue) => <span className={'fs-12'}>{inputValue} 추가</span>}
                            placeholder={'태그입력'}
                            name='tag'
                            options={[]}
                            onChange={(value) => onChangeVodInfo('tags', value.map((v) => v.value))}
                        />
                    </FormGroup>
                </div>
                <div className={'col-sm-5 d-block'}>
                    <FormGroup>
                        <Label for="exampleUrl">미리보기 이미지</Label>
                        <img
                            src={vodInfo.thumbnail ? vodInfo.thumbnail : `${process.env.PUBLIC_URL}/assets/images/img/no-image.png`}
                            width={'100%'}
                            style={{maxHeight: 350, objectFit: 'contain'}}
                        />
                    </FormGroup>
                    <div className={'d-flex justify-content-center align-items-center'}>
                        <Button onClick={changeThumbnail} variant="contained" color="secondary">
                            이미지변경
                        </Button>
                        <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleChangeThumbnail}
                            accept={'.jpg, .jpeg, .png, '}
                            style={{display: 'none'}}
                        />
                    </div>
                </div>
            </div>
            <div className={'d-flex justify-content-center mt-50'}>
                <Button onClick={onSaveVodInfo} variant="contained" color="primary">
                    보관
                </Button>
            </div>
        </div>
    )

    return (
        <Dialog
            open={show}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth='md'
            classes={{
                paper: 'vod-upload-modal'
            }}
        >
            <CustomDialogTitle onClose={onCloseDialog}>동영상 업로드</CustomDialogTitle>
            <DialogContent className={'vod-upload-content'}>
                {
                    formType === 'UPLOAD_FORM' ? renderDropZone() : renderVideoInfo()
                }
            </DialogContent>
        </Dialog>
    )
}

export default VodUploadModal;

VodUploadModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onFinish: PropTypes.func
}

VodUploadModal.defaultProps = {
    show: true,
    onClose: () => null,
    onFinish: () => null,
}