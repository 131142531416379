import React from 'react';
import {NavLink} from "react-router-dom";
import {ListItem, ListItemIcon} from "@material-ui/core";
import Avatar from "../Widgets/Avatar";

const ImgMenuItem = ({name, img, channelId}) => {
    return (
        <ListItem button component="li">
            <NavLink activeClassName="item-active d-flex flex-row align-items-center" to={'/channel/' + channelId}>
                <ListItemIcon className="menu-icon" style={{marginRight: '0.7rem'}}>
                    <Avatar alt="user profile" src={img} name={name} className="img-fluid rounded-circle" style={{width: 24, height: 24, backgroundColor: '#404040'}}/>
                </ListItemIcon>
                <span className="menu" style={{fontSize: 14}}>
                    {name}
                </span>
            </NavLink>
        </ListItem>
    )
}

export default ImgMenuItem;