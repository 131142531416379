import React from 'react';
import {Badge, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import Avatar from 'Components/Widgets/Avatar';
import {useDispatch, useSelector} from 'react-redux';
import {logoutUser,} from 'Store/Actions';
import * as Apis from "Api";

const UserBlock = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.authUser).userInfo;

    const onLogout = () => {
        Apis.logout().then(() => {
            dispatch(logoutUser());
        })
    }

    return (
        <UncontrolledDropdown nav className="list-inline-item header-user-block ml-2">
            <DropdownToggle nav className="p-0 d-flex flex-row align-items-center">
                {/*<Avatar*/}
                {/*    alt="user"*/}
                {/*    size={25}*/}
                {/*    src={userInfo.profile}*/}
                {/*    name={userInfo.nickname ? userInfo.nickname : userInfo.username}*/}
                {/*    className="rounded-circle border-info mr-10"*/}
                {/*    style={{display: 'inline-flex'}}*/}
                {/*/>*/}
                <Button aria-label="bell" style={{textTransform: 'unset'}}>
                    {userInfo.nickname ? userInfo.nickname : userInfo.username}
                    <i className="zmdi zmdi-chevron-down ml-1"/>
                </Button>
            </DropdownToggle>
            <DropdownMenu right>
                <ul className="list-unstyled mb-0">
                    <li className="p-15 border-bottom user-profile-top bg-primary rounded-top">
                        <p className="text-white mb-0 fs-14">{userInfo.nickname ? userInfo.nickname : userInfo.username}</p>
                    </li>
                    <li>
                        <Link to={{
                            pathname: '/channel/setting',
                            state: { activeTab: 0 }
                        }}>
                            <i className="zmdi zmdi-account text-primary mr-3"/>
                            <span>프로파일</span>
                        </Link>
                    </li>
                    <li className="border-top">
                        <a onClick={(e) => onLogout()}>
                            <i className="zmdi zmdi-power text-danger mr-3"/>
                            <span>로그아웃</span>
                        </a>
                    </li>
                </ul>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default UserBlock;