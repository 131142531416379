/**
 * Auth User Reducers
 */
import { Cookies } from 'react-cookie';
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    SIGNUP_USER_FAILURE
} from 'Store/Actions/types';

const cookie = new Cookies();

/**
 * initial auth user
 */

const INIT_STATE = {
    userInfo: cookie.get("user_info") ? cookie.get("user_info") : {},
    accessToken: cookie.get("access_token"),
    loading: false,
    isLogin: !!cookie.get("access_token")
};

let authUser = (state = INIT_STATE, action) => {
    switch (action.type) {

        case LOGIN_USER:
            return { ...state, loading: true };

        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userInfo: action.payload.info,
                accessToken: action.payload.accessToken,
                isLogin: true,
            };

        case LOGIN_USER_FAILURE:
            return { ...state, loading: false };

        case LOGOUT_USER:
            return { ...state, accessToken: '', isLogin: false, userInfo: {} };

        case SIGNUP_USER:
            return { ...state, loading: true };

        case SIGNUP_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload };

        case SIGNUP_USER_FAILURE:
            return { ...state, loading: false };

        default: return { ...state };
    }
}

export default authUser;