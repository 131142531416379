import React, {useState, useEffect, useRef} from "react";
import {CircularProgress, IconButton} from '@material-ui/core';
import {Input} from 'reactstrap';
import {useSelector} from "react-redux";
import socketIOClient from "socket.io-client";
import {Scrollbars} from 'react-custom-scrollbars';
import {v4 as uuidv4} from 'uuid';
import * as Apis from "Api";

const SiteSideChat = ({chatId}) => {
    const chatScroll = useRef();
    const authUser = useSelector(state => state.authUser);
    const isLogin = authUser.isLogin;
    const userInfo = authUser.userInfo;
    const [socket, setSocket] = useState(null);
    const [message, setMessage] = useState('')
    const [previousChats, setPreviousChats] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        setSocket(socketIOClient(Apis.wsChatAddress + chatId, {
            transports: ["websocket"]
        }));
        return () => {
            if (socket) {
                socket.disconnect();
            }
        }
    }, []);

    useEffect(() => {
        if (!socket) return;
        socket.on('connect', handleSocketConnect);
        socket.on('SRV_PREVIOUS_CHAT_LIST', handleLoadPreviousChat);
        socket.on('SRV_NEW_CHAT_MESSAGE', handleAddChatMsg);
        socket.on('SRV_SEND_POINT', handleAddPointMsg);
        return () => {
            socket.off('connect', handleSocketConnect);
            socket.off('SRV_PREVIOUS_CHAT_LIST', handleLoadPreviousChat);
            socket.off('SRV_NEW_CHAT_MESSAGE', handleAddChatMsg);
            socket.off('SRV_SEND_POINT', handleAddPointMsg);
        };
    }, [socket]);

    const handleSocketConnect = () => {
        setLoading(false);
        socket.emit('CLIENT_AUTHENTICATION', Apis.accessToken());
    }

    const handleLoadPreviousChat = (data) => {
        data.forEach((v) => {
            v.isMy = userInfo.id === v.userId
        });
        setPreviousChats(data);
        chatScroll.current.scrollToBottom();
    }

    const handleAddChatMsg = (data) => {
        data.isMy = userInfo.id === data.userId;
        setPreviousChats(previousChats => [
            ...previousChats,
            data
        ]);
        chatScroll.current.scrollToBottom();
    }

    const handleAddPointMsg = (data) => {
        console.log(data);
        data.isMy = userInfo.id === data.userId;
        setPreviousChats(previousChats => [
            ...previousChats,
            data
        ]);
        chatScroll.current.scrollToBottom();
    }

    const onSendMessage = () => {
        if (message !== '') {
            let data = {
                id: uuidv4(),
                isMy: true,
                type: 'chat',
                message: message,
                nickname: userInfo.nickname,
                profile: userInfo.profile,
                time: new Date(),
            }
            previousChats.push(data);
            setPreviousChats([...previousChats]);
            socket.emit('CLIENT_NEW_CHAT_MESSAGE', message);
            setMessage('');
            setTimeout(() => {
                chatScroll.current.scrollToBottom();
            }, 200);
        }
    }


    const renderChatItem = (item) => {
        return (
            <div key={item.id} className={'site-chat-item'}>
                <span className={'fs-12 fw-bold'}>{item.nickname} : </span>
                <span className={'fs-11'}>{item.message}</span>
            </div>
        )
    }

    return (
        <div className={'site-side-chat'}>
            <div className={'site-chat-statusbar d-flex flex-row justify-content-between pl-1 pr-1'}>
                <div className={'d-flex flex-row align-items-center'}>
                    <div>
                        <img src={require('Assets/img/icon_chat_user.png').default} alt=''/>
                    </div>
                    {/*<span className={'fs-10 ml-1'}>52467명</span>*/}
                </div>
                <div>
                    <IconButton>
                        <img src={require('Assets/img/icon_chat_edit.png').default} alt=''/>
                    </IconButton>
                    <IconButton>
                        <img src={require('Assets/img/icon_chat_erase.png').default} alt=''/>
                    </IconButton>
                    <IconButton>
                        <img src={require('Assets/img/icon_chat_plus.png').default} alt=''/>
                    </IconButton>
                    <IconButton>
                        <img src={require('Assets/img/icon_chat_minus.png').default} alt=''/>
                    </IconButton>
                    <IconButton>
                        <img src={require('Assets/img/icon_chat_refresh.png').default} alt=''/>
                    </IconButton>
                    <IconButton>
                        <img src={require('Assets/img/icon_chat_setting.png').default} alt=''/>
                    </IconButton>
                </div>
            </div>
            <div className={'site-chat-notify'}>
                응원글은 한줄로 (두줄 띄어쓰기 금지)
            </div>
            <Scrollbars
                className="site-chat-item-container"
                autoHide
                ref={chatScroll}
            >
                {
                    loading ?
                        <div className={'d-flex justify-content-center align-items-center'} style={{height: '100%'}}>
                            <CircularProgress size={'small'}/>
                        </div> :
                        <div className={'site-chat-items'}>
                            {
                                previousChats.map((v, i) => renderChatItem(v))
                            }
                        </div>
                }
            </Scrollbars>
            {
                isLogin ?
                    <div className={'site-chat-footer'}>
                        <Input
                            type="text"
                            value={message}
                            autoComplete={"off"}
                            onChange={(event) => setMessage(event.target.value)}
                        />
                        <IconButton onClick={onSendMessage}>
                            <img src={require('Assets/img/icon_chat_send.png').default} alt=''/>
                        </IconButton>
                    </div> :
                    <div className={'site-chat-footer fs-12 pl-10'}>
                        채팅을 시작하려면 로그인하세요
                    </div>
            }
        </div>
    )
}

export default SiteSideChat;