import React, {useState, useEffect} from "react";
import ReactQuill from 'react-quill';
import {Button} from '@material-ui/core';
import {Input} from 'reactstrap';
import {useHistory} from "react-router-dom";
import * as Apis from 'Api';
import {NotificationManager} from "react-notifications";

const CommunityNew = () => {
    const history = useHistory();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const onSubmit = () => {
        if(title.length === 0) {

        }
        Apis.communityNew(title, content).then((resp) => {
            history.replace('/site/community')
        }).catch((e) => {
            NotificationManager.error('글등록중 오류가 발생하였습니다.');
        });
    }

    const onCancel = () => {
        setTitle('');
        setContent('');
    }

    return (
        <div className={'site-community-new-container'}>
            <h2 className={'fs-17 ff-dream6 mb-20'}>커뮤니티</h2>
            <div className={'mb-10'}>
                <Input
                    className={'fs-13'}
                    type={'text'}
                    placeholder={'제목을 입력해주세요'}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>
            <ReactQuill
                value={content}
                onChange={(content, delta, source, editor) => setContent(content)}
            />
            <div className={'d-flex flex-column ff-dream4 fs-12 mt-2'}>
                <span>※ 다른 회원들에게 협오감을 줄수 있는 게시글 등록은 자제해 주세요</span>
            </div>
            <div className={'d-flex flex-row justify-content-end mt-10'}>
                <Button className={'site-community-new-btn cancel'} onClick={onCancel}>
                    취소
                </Button>
                <Button className={'site-community-new-btn submit'} onClick={onSubmit} disabled={title.length === 0 || content.length === 0}>
                    등록
                </Button>
            </div>
        </div>
    )
}

export default CommunityNew;