/**
 * Signin Firebase
 */

import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, AppBar, Toolbar} from '@material-ui/core';
import {NotificationManager} from "react-notifications";
import {Link} from 'react-router-dom';
import {Form, FormGroup, Input} from 'reactstrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import QueueAnim from 'rc-queue-anim';
import {signinUser,} from 'Store/Actions';
import * as Apis from "Api";

function Signin(props) {
    const settings = useSelector(state => state.settings);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    // const loading = useSelector(state => state.loading);

    /**
     * On User Login
     */
    const onUserLogin = () => {
        setLoading(true);
        if (email !== '' && password !== '') {
            Apis.login(email, password).then((result) => {
                setLoading(false);
                dispatch(signinUser(result.info, result.token, props.history));
            }).catch((e) => {
                NotificationManager.error('아이디나 암호가 정확하지 않습니다');
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    return (
        <QueueAnim type="bottom" duration={2000}>
            <div className="rct-session-wrapper">
                {loading &&
                <LinearProgress/>
                }
                <div className="session-inner-wrapper">
                    <div className="container">
                        <div className="session-body text-center">
                            <div className="session-head mb-30">
                                <img
                                    src={
                                        !settings.darkMode ?
                                            `${process.env.PUBLIC_URL}/assets/images/img/logo-light.png` :
                                            `${process.env.PUBLIC_URL}/assets/images/img/logo-dark.png`
                                    }
                                    className="img-fluid mb-3" alt="site-logo" width="200" height="auto"
                                />
                                <h2 className="fs-24 font-weight-bold">로그인</h2>
                            </div>
                            <Form>
                                <FormGroup className="has-wrapper">
                                    <Input
                                        type="mail"
                                        value={email}
                                        name="user-mail"
                                        id="user-mail"
                                        className="has-input input-lg"
                                        placeholder="아이디를 입력하세요"
                                        onChange={(event) => setEmail(event.target.value)}
                                    />
                                    <span className="has-icon"><i className="ti-email"/></span>
                                </FormGroup>
                                <FormGroup className="has-wrapper">
                                    <Input
                                        value={password}
                                        type="Password"
                                        name="user-pwd"
                                        id="pwd"
                                        className="has-input input-lg"
                                        placeholder="비밀번호를 입력하세요"
                                        onChange={(event) => setPassword(event.target.value)}
                                    />
                                    <span className="has-icon"><i className="ti-lock"/></span>
                                </FormGroup>
                                <FormGroup className="mb-15">
                                    <Button
                                        color="primary"
                                        className="btn-block text-white w-100"
                                        variant="contained"
                                        size="large"
                                        onClick={onUserLogin}
                                    >
                                        로그인
                                    </Button>
                                </FormGroup>
                            </Form>
                            <div className={'mt-30'}>
                                {/* <Link to="/signup" className="signup-btn"><u>회원가입</u></Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </QueueAnim>
    );
}

export default Signin;
