import React, {useState, useEffect} from 'react'
import {Avatar, Button, IconButton, Input, Menu, MenuItem} from "@material-ui/core";
import {useLocation, useHistory} from "react-router-dom";
import ThumbUpIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownIcon from '@material-ui/icons/ThumbDownAlt';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import {useSelector} from "react-redux";
import * as Apis from 'Api';

const VodCommentReply = ({vodId, thumbnail}) => {
    const isLogin = useSelector(state => state.authUser).isLogin;
    let userInfo = useSelector(state => state.authUser).userInfo;
    if (!userInfo) userInfo = {};
    const [commentText, setCommentText] = useState('');
    const [commentList, setCommentList] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuCommentId, setMenuCommentId] = useState();
    const [editingList, setEditingList] = useState({});
    const [addingChildList, setAddingChildList] = useState({});
    const [showingChildList, setShowingChildList] = useState({});
    const history = useHistory();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        Apis.getVodCommentList(vodId).then((resp) => {
            setCommentList(resp);
        }).catch((e) => {
            console.error(e);
        });
    }

    const onCommentMenuClose = () => {
        setMenuCommentId('');
        setAnchorEl(null);
    };

    const onOpenCommentMenu = (event, commentId) => {
        setMenuCommentId(commentId);
        setAnchorEl(event.currentTarget);
    }

    const onAddComment = (parentId, text) => {
        if (text === '') return;
        Apis.addVodComment(vodId, parentId, text).then((resp) => {
            if (!parentId) {
                // root comment
                setCommentText('');
            } else {
                // child comment
                addingChildList[parentId] = undefined;
                setAddingChildList({...addingChildList});
            }
            getData();
        }).catch((e) => {
            console.error(e);
        });
    }

    const onDeleteComment = () => {
        Apis.deleteVodComment(menuCommentId).then((resp) => {
            setMenuCommentId('');
            setAnchorEl(null);
            getData();
        }).catch((e) => {
            console.error(e);
        });
    }

    const onUpdateComment = () => {
        let editItem;
        commentList.forEach((v) => {
            if(v.id === menuCommentId) editItem = v;
            v.children && v.children.forEach((vv) => {
                if(vv.id === menuCommentId) editItem = vv;
            })
        })
        if (editItem) {
            onChangeEditingTest(menuCommentId, editItem.content);
        }
        setMenuCommentId('');
        setAnchorEl(null);
    }

    const onUpdateCommentText = (id) => {
        Apis.updateVodComment(id, editingList[id]).then((resp) => {
            onChangeEditingTest(id, undefined);
            getData()
        }).catch((e) => {

        });
    }

    const onChangeEditingTest = (id, text) => {
        editingList[id] = text;
        setEditingList({...editingList});
    }

    const onLikeComment = (id, likeType) => {
        Apis.setVodCommentLike(id, likeType).then((resp) => {
           getData();
        }).catch((e) => {

        });
    }

    const onChangeChildAddingText = (id, text) => {
        addingChildList[id] = text;
        setAddingChildList({...addingChildList});
    }

    const onAddChildComment = (id) => {
        onChangeChildAddingText(id, '')
        showingChildList[id] = 'show';
        setShowingChildList({...showingChildList});
    }

    const onExpandChildComment = (id) => {
        if (showingChildList[id]) {
            showingChildList[id] = undefined;
        } else {
            showingChildList[id] = 'show'
        }
        setShowingChildList({...showingChildList});
    }



    const renderCommentMenu = (commentId) => (
        <div className={'comment-menu'} key={commentId}>
            <IconButton
                size={'small'}
                aria-owns={anchorEl ? 'simple-menu' : null}
                aria-haspopup="true"
                onClick={(event) => onOpenCommentMenu(event, commentId)}
            >
                <i className="zmdi zmdi-more-vert"/>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onCommentMenuClose}
            >
                <MenuItem onClick={onUpdateComment}>수정</MenuItem>
                <MenuItem onClick={onDeleteComment}>삭제</MenuItem>
            </Menu>
        </div>
    )

    const renderChildComment = (item) => {
        return (
            <div>
                {
                    (item.children && item.children.length > 0) &&
                    <div className={'mt-1 mb-1 fs-13 cursor-pointer'} onClick={() => onExpandChildComment(item.id)}>
                        {showingChildList[item.id] ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                        답글보기
                    </div>
                }
                {
                    showingChildList[item.id] && item.children.map((v) =>
                        <div className={'d-flex mt-2 mb-2'} key={v.id}>
                            <Avatar className={'child-avatar'} src={v.userInfo.profile} name={v.userInfo.nickname}/>
                            {
                                !editingList[v.id] ?
                                    <div className={'d-flex flex-grow-1 comment-content-block'}>
                                        <div className={'flex-grow-1'}>
                                            <div className={'comment-user-info cursor-pointer mt-1 mb-1'}>
                                                {v.userInfo.username} <span className={'fs-11 ml-1'}>{v.pastTime}전</span>
                                            </div>
                                            <div className={'fs-13'}>{v.content}</div>
                                            <div className={'thumb-block mt-1'}>
                                                <IconButton aria-label="delete" size="small" onClick={() => onLikeComment(v.id, true)}>
                                                    <ThumbUpIcon fontSize="small"/>
                                                </IconButton>
                                                <span className={'ml-1 mr-10'}>{v.like !== 0 && v.like}</span>
                                                <IconButton aria-label="delete" size="small" onClick={() => onLikeComment(v.id, false)}>
                                                    <ThumbDownIcon fontSize="small"/>
                                                </IconButton>
                                                <span className={'ml-1 mr-20'}>{v.dislike !== 0 && v.dislike}</span>
                                            </div>
                                        </div>
                                        {
                                            (isLogin && v.userInfo.id === userInfo.id) && renderCommentMenu(v.id)
                                        }
                                    </div> :
                                    <div className={'d-flex flex-grow-1'}>
                                        <Input
                                            type="text" className={'comment-input flex-grow-1'} placeholder={"댓글을 추가하세요"} value={editingList[v.id]}
                                            onChange={(e) => onChangeEditingTest(v.id, e.target.value)}
                                        />
                                        <Button onClick={() => onChangeEditingTest(v.id, undefined)} variant="outlined" color="primary" className={'comment-btn ml-20'}>
                                            취소
                                        </Button>
                                        <Button onClick={() => onUpdateCommentText(v.id)} variant="outlined" color="primary" className={'comment-btn ml-2'}>
                                            수정
                                        </Button>
                                    </div>

                            }
                        </div>
                    )
                }
                {
                    showingChildList[item.id] && addingChildList[item.id] !== undefined &&
                    <div className={'d-flex mt-2'}>
                        <Avatar className={'child-avatar'}/>
                        <Input
                            type="text" className={'comment-input flex-grow-1'} placeholder={isLogin ? "댓글을 추가하세요" : "댓글을 입력하려면 로그인을 해야 합니다"} value={addingChildList[item.id]}
                            onChange={(e) => onChangeChildAddingText(item.id, e.target.value)}
                            disabled={!isLogin}
                        />
                        <Button onClick={() => onChangeChildAddingText(item.id, undefined)} variant="outlined" color="primary" className={'comment-btn ml-20'}>
                            취소
                        </Button>
                        <Button onClick={() => onAddComment(item.id, addingChildList[item.id])} disabled={!isLogin} variant="outlined" color="primary" className={'comment-btn ml-20'}>
                            답글
                        </Button>
                    </div>
                }
            </div>
        )
    }
    if(commentList.length===0)
    return(<></>)
    else
    return (
        <tr className={'text-center'} key={vodId}>
            <td>
                <div className={'video-comment-block'}>
                    
                    {
                        commentList.map((v) => (
                            <div key={v.id}>
                                <div className={'comment-item'} style={{display:'-webkit-box'}}>
                                    <Avatar className="mr-30" src={v.userInfo.profile}/>
                                    <div className={'flex-grow-1'}>
                                        {
                                            !editingList[v.id] ?
                                                <div className={'d-flex comment-content-block'}>
                                                    <div className={'flex-grow-1'}>
                                                        <div className={'comment-user-info cursor-pointer mt-2 mb-2'}>
                                                            {v.userInfo.username} <span className={'fs-11 ml-1'}>{v.pastTime}전</span>
                                                        </div>
                                                        <div>
                                                            {v.content}
                                                        </div>
                                                    </div>
                                                    {
                                                        (isLogin && v.userInfo.id === userInfo.id) && renderCommentMenu(v.id)
                                                    }
                                                </div> :
                                                <div className={'d-flex'}>
                                                    <Input
                                                        type="text" className={'comment-input flex-grow-1'} placeholder={"댓글을 추가하세요"} value={editingList[v.id]}
                                                        onChange={(e) => onChangeEditingTest(v.id, e.target.value)}
                                                    />
                                                    <Button onClick={() => onChangeEditingTest(v.id, undefined)} variant="outlined" color="primary" className={'comment-btn ml-20'}>
                                                        취소
                                                    </Button>
                                                    <Button onClick={() => onUpdateCommentText(v.id)} variant="outlined" color="primary" className={'comment-btn ml-2'}>
                                                        수정
                                                    </Button>
                                                </div>
                                        }
                                        <div className={'thumb-block'}>
                                            <IconButton aria-label="delete" size="small" onClick={() => onLikeComment(v.id, true)}>
                                                <ThumbUpIcon fontSize="small"/>
                                            </IconButton>
                                            <span className={'ml-1 mr-10'}>{v.like !== 0 && v.like}</span>
                                            <IconButton aria-label="delete" size="small" onClick={() => onLikeComment(v.id, false)}>
                                                <ThumbDownIcon fontSize="small"/>
                                            </IconButton>
                                            <span className={'ml-1 mr-20'}>{v.dislike !== 0 && v.dislike}</span>
                                            <span onClick={() => onAddChildComment(v.id)} style={{cursor: 'pointer'}}>답글</span>
                                        </div>
                                        {
                                            renderChildComment(v)
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </td>
            <td>
                <img
                    src={thumbnail ? thumbnail : `${process.env.PUBLIC_URL}/assets/images/img/no-image.png`}
                    alt=''
                    width={120}
                    style={{maxHeight: 67, objectFit: 'contain', cursor:'pointer'}}
                    onClick={() => history.push('/view/' + vodId)}
                />
            </td>
        </tr>

    );
}

export default VodCommentReply;