/**
 * Chat Area Component
 */
import React, {useState, useRef, useEffect} from 'react';
import {FormGroup, Input} from 'reactstrap';
import {Scrollbars} from 'react-custom-scrollbars';
import {useDispatch, useSelector} from 'react-redux';
import {Button, IconButton, Menu, MenuItem, CircularProgress} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SwipeableViews from 'react-swipeable-views';
import socketIOClient from 'socket.io-client';
import {withRouter} from 'react-router-dom';
import Avatar from "Components/Widgets/Avatar";
import MessageBlock from 'Components/Chat/MessageBlock';
import ChatEmojiPicker from 'Components/Chat/ChatEmojiPicker';
import ChatPointSend from 'Components/Chat/ChatPointSend';
import * as Apis from 'Api';

function ChatComponent({liveId}) {
    const chatScroll = useRef();
    const authUser = useSelector(state => state.authUser);
    const isLogin = authUser.isLogin;
    const userInfo = authUser.userInfo;
    const [socket, setSocket] = useState(null);
    const [slideIndex, setSlideIndex] = useState(0);
    const [message, setMessage] = useState('')
    const [previousChats, setPreviousChats] = useState([]);
    const [connectUserList, setConnectUserList] = useState([]);
    const [showTimeStamp, setShowTimeStamp] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [showEmojPicker, setShowEmojPicker] = useState(false);
    const [showPointSend, setShowPointSend] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setSocket(socketIOClient(Apis.wsChatAddress + liveId, {
            transports: ["websocket"]
        }));
        return () => {
            if (socket) {
                socket.disconnect();
            }
        }
    }, []);

    useEffect(() => {
        if (!socket) return;
        socket.on('connect', handleSocketConnect);
        socket.on('SRV_PREVIOUS_CHAT_LIST', handleLoadPreviousChat);
        socket.on('SRV_NEW_CHAT_MESSAGE', handleAddChatMsg);
        socket.on('SRV_SEND_POINT', handleAddPointMsg);
        socket.on('SRV_CONNECT_USER_LIST', handleConnectUserList);
        return () => {
            socket.off('connect', handleSocketConnect);
            socket.off('SRV_PREVIOUS_CHAT_LIST', handleLoadPreviousChat);
            socket.off('SRV_NEW_CHAT_MESSAGE', handleAddChatMsg);
            socket.off('SRV_SEND_POINT', handleAddPointMsg);
            socket.off('SRV_CONNECT_USER_LIST', handleConnectUserList);
        };
    }, [socket]);

    const handleSocketConnect = () => {
        setLoading(false);
        socket.emit('CLIENT_AUTHENTICATION', Apis.accessToken());
    }

    const handleLoadPreviousChat = (data) => {
        data.forEach((v) => {
            v.isMy = userInfo.id === v.userId
        });
        setPreviousChats(data);
        chatScroll.current.scrollToBottom();
    }

    const handleAddChatMsg = (data) => {
        data.isMy = userInfo.id === data.userId;
        setPreviousChats(previousChats => [
            ...previousChats,
            data
        ]);
        chatScroll.current.scrollToBottom();
    }

    const handleAddPointMsg = (data) => {
        console.log(data);
        data.isMy = userInfo.id === data.userId;
        setPreviousChats(previousChats => [
            ...previousChats,
            data
        ]);
        chatScroll.current.scrollToBottom();
    }

    const handleConnectUserList = (userList) => {
        setConnectUserList(userList);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const chatOptionsHandler = event => {
        setAnchorEl(event.currentTarget);
    }

    const handleMenuUserList = () => {
        socket.emit('CLIENT_CONNECT_USER_LIST', '');
        setSlideIndex(1);
        handleMenuClose();
    }

    const handleShowTimeStamp = () => {
        setShowTimeStamp(!showTimeStamp);
        setAnchorEl(null);
    }

    const onSendMessage = () => {
        if (message !== '') {
            let data = {
                isMy: true,
                type: 'chat',
                message: message,
                nickname: userInfo.nickname,
                profile: userInfo.profile,
                time: new Date(),
            }
            previousChats.push(data);
            setPreviousChats([...previousChats]);
            socket.emit('CLIENT_NEW_CHAT_MESSAGE', message);
            setMessage('');
            setTimeout(() => {
                chatScroll.current.scrollToBottom();
            }, 200);
        }
    }

    const onSendPoint = (point, message) => {
        let data;
        if (userInfo.userPoint && userInfo.userPoint > point) {
            data = {
                isMy: true,
                type: 'point',
                message,
                nickname: userInfo.nickname,
                profile: userInfo.profile,
                time: new Date(),
                point
            };
            socket.emit('CLIENT_SEND_POINT', {point, message});
        } else {
            data = {
                isMy: true,
                type: 'point',
                nickname: userInfo.nickname,
                profile: userInfo.profile,
                time: new Date(),
                point: -1       // point does not enough!
            }
        }
        previousChats.push(data);
        setPreviousChats([...previousChats]);
        setTimeout(() => {
            chatScroll.current.scrollToBottom();
        }, 200);
    }

    const renderChatComponent = () => (
        <div className={'d-flex flex-column flex-grow-1'}>
            <Scrollbars
                className="chat-body-wrapper"
                autoHide
                ref={chatScroll}
            >
                {
                    loading ?
                        <div className={'d-flex justify-content-center align-items-center'} style={{height: '100%'}}>
                            <CircularProgress/>
                        </div> :
                        <div className="chat-body p-30">
                            {previousChats.map((previousChat, key) => (
                                <MessageBlock
                                    even={!previousChat.isMy}
                                    key={key}
                                    selectedUserPhotoUrl={previousChat.profile}
                                    data={previousChat}
                                    showTimeStamp={showTimeStamp}
                                />
                            ))}
                        </div>
                }
            </Scrollbars>
            {
                !isLogin ?
                    <div className="chat-footer d-flex flex-column">
                        <div className={'chat-login-msg'}>
                            채팅을 하려면 로그인을 해야 합니다
                        </div>
                    </div> :
                    <div className="chat-footer d-flex flex-column">
                        {
                            !showPointSend &&
                            <div>
                                <div className={'d-flex px-4 align-items-center pb-1 pt-1'}>
                                    <div className="mr-3 w-100">
                                        <Input
                                            type="text"
                                            id="search-msg"
                                            placeholder="메시지 입력하기"
                                            value={message}
                                            className="msg-input"
                                            autoComplete={"off"}
                                            onChange={(event) => setMessage(event.target.value)}
                                            onKeyDown = {(e) => {
                                                if (e.key === "Enter"){
                                                    onSendMessage();
                                                }        
                                            }}
                                        />
                                    </div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => onSendMessage()}
                                        className="submit-btn bg-primary"
                                    >
                                        <i className="zmdi zmdi-mail-send ml-2 fs-21"/>
                                    </Button>
                                </div>
                                <div className={'chat-tools d-flex flex-column pb-2'}>
                                    {
                                        showEmojPicker && <ChatEmojiPicker onSelect={(emoji) => setMessage(message + emoji.native)}/>
                                    }
                                    <div className={'d-flex flex-row'}>
                                        <IconButton className="text-white" onClick={() => setShowEmojPicker(!showEmojPicker)}>
                                            <i className="zmdi zmdi-mood text-white"/>
                                        </IconButton>
                                        <IconButton className="text-white ml-1" onClick={() => setShowPointSend(!showPointSend)}>
                                            <AttachMoneyIcon className={'money-icon'}/>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            showPointSend && <ChatPointSend onSendPoint={onSendPoint} onClose={() => setShowPointSend(false)}/>
                        }
                    </div>
            }
        </div>
    )

    const renderConnectUserList = () => (
        <div className={'p-20'}>
            {
                connectUserList.map((v) => (
                    <div className={'d-flex align-items-center mt-10 mt-10 cursor-pointer'} key={v.id}>
                        <Avatar alt="user profile" src={v.profile} name={v.nickname} className="img-fluid rounded-circle mr-20"/>
                        {v.nickname}
                    </div>
                ))
            }
        </div>
    )

    return (
        <div className={'chat-wrapper'}>
            <div className="chat-main-body">
                <div className="chat-head">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="media align-items-center">
                            {
                                slideIndex === 0 ?
                                    <div className="media-body mt-1 ml-2">
                                        <h5 className="mb-0">채팅</h5>
                                    </div> :
                                    <div className="media-body mt-1 d-flex align-items-center">
                                        <IconButton onClick={() => setSlideIndex(0)} size={'small'} style={{height: '2rem', width: '2rem'}}>
                                            <ArrowBackIcon fontSize={'small'}/>
                                        </IconButton>
                                        <h5 className="mb-0">참가자</h5>
                                    </div>
                            }
                        </div>
                        <div>
                            <IconButton
                                size={'small'}
                                aria-owns={anchorEl ? 'simple-menu' : null}
                                aria-haspopup="true"
                                onClick={chatOptionsHandler}
                            >
                                <i className="zmdi zmdi-more-vert"/>
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleMenuUserList}>참가자</MenuItem>
                                <MenuItem onClick={handleShowTimeStamp}>타임스탬프 전환</MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
                <SwipeableViews className={'chat-block-slide'} index={slideIndex}>
                    {renderChatComponent()}
                    {renderConnectUserList()}
                </SwipeableViews>
            </div>
        </div>
    );
}

export default withRouter(ChatComponent);
