/**
 * App Routes
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Sidebar from 'react-sidebar';
import {Scrollbars} from 'react-custom-scrollbars';
import Header from 'Components/Header/Header';
import SidebarContent from 'Components/Sidebar';
import {collapsedSidebarAction} from 'Store/Actions';

class MainApp extends Component {

    state = {}

    UNSAFE_componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     const {windowWidth} = this.state;
    //     if (nextProps.location !== this.props.location) {
    //         if (windowWidth <= 1199) {
    //             this.props.collapsedSidebarAction(false);
    //         }
    //     }
    // }

    updateDimensions = () => {
        this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight});
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    renderPage() {
        const {pathname} = this.props.location;
        const {children} = this.props;
        if (pathname === '/chat' || pathname.startsWith('/view') || pathname.startsWith('/live')) {
            return (
                <div className="rct-page-content p-0">
                    {children}
                </div>
            );
        }
        return (
            <Scrollbars
                className="rct-scroll"
                autoHide
                autoHideDuration={100}
                style={this.getScrollBarStyle()}
            >
                <div className="rct-page-content">
                    {children}
                </div>
            </Scrollbars>
        );
    }

    // render header
    renderHeader() {
        return <Header/>
    }

    //render Sidebar
    renderSidebar() {
        return <SidebarContent/>
    }

    //Scrollbar height
    getScrollBarStyle() {
        return {
            height: 'calc(100vh - 50px)'
        }
    }

    render() {
        const {navCollapsed, rtlLayout, miniSidebar} = this.props.settings;
        const {windowWidth} = this.state;
        return (
            <div className="app">
                <div className="app-main-container">
                    <div className="app-container">
                        <div className="rct-app-content">
                            <div className="app-header">
                                {this.renderHeader()}
                            </div>

                            <Sidebar
                                sidebar={this.renderSidebar()}
                                // open={windowWidth <= 1199 ? navCollapsed : false}
                                // docked={windowWidth > 1199 ? navCollapsed : false}
                                open={!navCollapsed}
                                docked={windowWidth > 1199 ? !navCollapsed : false}
                                pullRight={rtlLayout}
                                onSetOpen={() => this.props.collapsedSidebarAction(true)}
                                rootClassName={'sidebar-root'}
                                sidebarClassName={'sidebar-sidebar'}
                                contentClassName={'sidebar-content'}
                                // contentClassName={classnames({'app-conrainer-wrapper': miniSidebar})}
                            >
                                <div className="rct-page">
                                    {this.renderPage()}
                                </div>
                            </Sidebar>
                        </div>
                        </div>
                </div>
            </div>
        );
    }
}

// map state to props
const mapStateToProps = ({settings}) => {
    return {settings}
}

export default withRouter(connect(mapStateToProps, {
    collapsedSidebarAction
})(MainApp));
