import React, {useEffect, useState} from 'react';
import RctAppLayout from 'Components/RctAppLayout';
import {useHistory, useLocation} from 'react-router-dom';
import ContentItem from './ContentItem';
import {collapsedSidebarAction} from "../../Store/Actions";
import {useDispatch} from "react-redux";
import * as Apis from 'Api';
import HomeChat from "./HomeChat";
import SwipeableViews from "react-swipeable-views";

const Home = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    
    const query = new URLSearchParams(useLocation().search);
    const chatIdList = [
        {chatId: 'sport-chat', chatTitle: '라이브채팅'}
    ]
    const [activeChatIndex, setActiveChatIndex] = useState(0);
    const [state, setState] = useState({
        videoList: []
    });

    useEffect(() => {
        dispatch(collapsedSidebarAction(false));
    }, []);

    useEffect(() => {
        getData();
    }, [history.location]);

    const getData = () => {
        const searchText = query.get('search');
        Apis.getVideoList(searchText).then((resp) => {
            setState({
                ...state,
                videoList: resp
            });
        }).catch((e) => {

        });
    }

    return (
        <RctAppLayout>
            <div className={'home-container'}>
                <div className={'home-content-block'}>
                    {
                        state.videoList.map((v) =>
                            <ContentItem content={v} key={v.id} onClick={() => history.push('/view/' + v.id)}/>
                        )
                    }
                </div>
                {/*<HomeChat />*/}
                <div className={'chat-wrapper home-chat'}>
                    <div className="chat-main-body">
                        <div className="chat-head p-0">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        {
                                            chatIdList.map((v, i) => (
                                                <div key={v.chatId} className={'home-chat-title ' + (i === activeChatIndex ? 'active-title' : '')} onClick={() => setActiveChatIndex(i)}>
                                                    {v.chatTitle}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SwipeableViews className={'chat-block-slide'} index={0}>
                            {
                                chatIdList.filter((v, i) => i === activeChatIndex).map((v) => (
                                    <HomeChat key={v.chatId} chatId={v.chatId}/>
                                ))
                            }
                            {/*{renderChatComponent()}*/}
                        </SwipeableViews>
                    </div>
                </div>
            </div>
        </RctAppLayout>
    )

};

export default Home;