// sidebar nav links
let sidebarMenu = {
    mainCategory1: [
        {
            "menu_title": "홈",
            "menu_icon": "zmdi zmdi-home",
            "path": "/videos",
            "new_item": false,
            "child_routes": null
        },
        // {
        //     "menu_title": "탐색",
        //     "menu_icon": "zmdi zmdi-compass",
        //     "path": "/videos",
        //     "new_item": false,
        //     "child_routes": null
        // },
        // {
        //     "menu_title": "구독",
        //     "menu_icon": "zmdi zmdi-collection-video",
        //     "path": "/channel",
        //     "new_item": false,
        //     "child_routes": null
        // },
    ],
    mainCategory2: [
        {
            "menu_title": "보관함",
            "menu_icon": "zmdi zmdi-collection-video",
            "path": "/feed/library",
            "new_item": false,
            "child_routes": null
        },
        {
            "menu_title": "시청기록",
            "menu_icon": "zmdi zmdi-time-restore",
            "path": "/feed/playlist?list=hl",
            "new_item": false,
            "child_routes": null
        },
        {
            "menu_title": "내 동영상",
            "menu_icon": "zmdi zmdi-tv-play",
            "path": "/channel/vods",
            "new_item": false,
            "child_routes": null
        },
        {
            "menu_title": "나중에 볼 동영상",
            "menu_icon": "zmdi zmdi-time",
            "path": "/feed/playlist?list=wl",
            "new_item": false,
            "child_routes": null
        },
        {
            "menu_title": "좋아요 표시한 동영상",
            "menu_icon": "zmdi zmdi-thumb-up",
            "path": "/feed/playlist?list=ll",
            "new_item": false,
            "child_routes": null
        },
    ],
    mainCategory3: [
    ],
    mainCategory4: [
        {
            "menu_title": "설정",
            "menu_icon": "zmdi zmdi-settings",
            "path": "/channel/setting",
            "new_item": false,
            "child_routes": null
        },
        {
            "menu_title": "고객센터",
            "menu_icon": "zmdi zmdi-help-outline",
            "path": "/",
            "new_item": false,
            "child_routes": null
        },
    ],



    channelCategory: [
        {
            "menu_title": "대시보드",
            "menu_icon": "zmdi zmdi-view-dashboard",
            "path": "/videos",
            "new_item": false,
            "child_routes": null
        },
        {
            "menu_title": "콘텐츠",
            "menu_icon": "zmdi zmdi-collection-video",
            "path": "/channel/vods",
            "new_item": false,
            "child_routes": null
        },
        // {
        //     "menu_title": "재생목록",
        //     "menu_icon": "zmdi zmdi-view-list-alt",
        //     "path": "/channel/playlist",
        //     "new_item": false,
        //     "child_routes": null
        // },
        {
            "menu_title": "댓글",
            "menu_icon": "zmdi zmdi-comment-text-alt",
            "path": "/channel/comments",
            "new_item": false,
            "child_routes": null
        },
    ]
}

export default sidebarMenu