import React, {useState, useEffect} from "react";
import SiteHeader from "./SiteHeader";
import SiteSidebar from "./SiteSidebar";
import {Scrollbars} from 'react-custom-scrollbars';

const RctSiteLayout = ({children}) => {
    return (
        <div className={'site-app'}>
            <div className={'site-container'}>
                <div className={'site-header'}>
                    <SiteHeader/>
                </div>
                <div className={'site-content'}>
                    <Scrollbars autoHide className={'site-sidebar'}>
                        <SiteSidebar/>
                    </Scrollbars>
                    <Scrollbars autoHide className={'site-page'}>
                        <div className={'p-20'}>
                            {children}
                        </div>
                    </Scrollbars>
                </div>
            </div>
        </div>
    );
}

export default RctSiteLayout;