import React, { Component } from 'react';

export default class FlvPlayer extends Component {
    constructor(props, context) {
        super(props);
        this.player = new NodePlayer();
    }

    componentDidMount() {
        // `src` is the property get from this component
        // `video` is the property insert from `Video` component
        // `video` is the html5 video element
        
        const { src, video } = this.props;
        this.player.setView("video1");
        this.player.setBufferTime(1000);
        this.player.start(src);
        
    }

    componentWillUnmount() {
        // destroy hls video source
        
        if (this.player) {
            this.player.stop();
        }
    }

    render() {
        return <canvas id="video1" style={{"width":"100%", "height":"100%", "position":"relative", "backgroundColor": "black"}}></canvas>
            
    }
}