import React from 'react';
import {Pagination} from '@material-ui/lab';
import PropTypes from 'prop-types';

const PaginationComponent = ({totalItems, pageSize, activePage, onSelect}) => {
    const auxPages = totalItems / pageSize;
    let pages = parseInt(auxPages, 10);
    pages += pages !== auxPages ? 1 : 0;

    if (totalItems > pageSize) {
        return (
            <div className={'d-flex justify-content-end'}>
                <Pagination
                    className="m-3" showFirstButton showLastButton
                    color="primary"
                    siblingCount={3}
                    defaultPage={activePage}
                    count={pages}
                    onChange={(e, page) => onSelect(page)}
                />
            </div>
        )
    } else {
        return null;
    }
}

PaginationComponent.propTypes = {
    totalItems: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
    maxPaginationNumbers: PropTypes.number,
    activePage: PropTypes.number
}

PaginationComponent.defaultProps = {
    maxPaginationNumbers: 5,
    activePage: 1
}

export default PaginationComponent;