import React, {useState} from 'react'
import {Button, IconButton, Slider} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AutosizeInput from 'react-input-autosize';
import {Input} from "reactstrap";

const ChatPointSend = ({onSendPoint, onClose}) => {
    const [message, setMessage] = useState('');
    const [point, setPoint] = useState(10);
    const [isEditPoint, setIsEditPoint] = useState(false);
    const pointStep = [10, 20, 30, 50, 100, 200, 500, 1000, 1500, 2000, 5000];

    const onSend = () => {
        onSendPoint(point, message);
        onClose();
    }

    const onChangeSlide = (index) => {
        setPoint(pointStep[index]);
        setIsEditPoint(false);
    }

    const handleKeyPress = (target) => {
        if(target.charCode==13){
          onSend()
        } 
    }

    return (
        <div className={'point-picker'}>
            <div className={'d-flex'}>
                <IconButton className="text-white p-2 " onClick={onClose}>
                    <CloseIcon fontSize={'small'}/>
                </IconButton>
                <span className={'fs-17 fw-bold ml-30 mt-1'}>포인트 보내기</span>
            </div>
            <div className={'d-flex justify-content-center fs-21 mt-30 mb-2'} onClick={() => setIsEditPoint(true)}>
                <AutosizeInput
                    type="number"
                    value={point}
                    className="point-input"
                    autoComplete={"off"}
                    disabled={!isEditPoint}
                    onChange={(event) => setPoint(event.target.value)}
                />
                <span className={'ml-2'} style={{paddingTop: 2}}>포인트</span>
            </div>
            <div className={'point-slider'}>
                <Slider
                    defaultValue={0}
                    valueLabelDisplay="off"
                    step={10}
                    marks
                    min={0}
                    max={100}
                    onChange={(e, value) => onChangeSlide([value / 10])}
                />
            </div>
            <div className={'mt-2 pl-20 pr-20'}>
                <Input
                    type="text"
                    id="search-msg"
                    placeholder="메시지 입력"
                    value={message}
                    className="msg-input"
                    autoComplete={"off"}
                    onChange={(event) => setMessage(event.target.value)}
                    onKeyPress={(e) => {
                        
                        if (e.key === "Enter"){
                            onSend();
                        }                           
                    }}
                />
            </div>
            <Button onClick={onSend} className="btn-primary text-white ml-20 mr-20 mt-3" autoFocus>
                포인트보내기
            </Button>
        </div>
    )
}

export default ChatPointSend;