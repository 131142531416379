/**
 * App Header
 */
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, IconButton, Drawer, AppBar, Toolbar, Tooltip, Menu, MenuItem} from '@material-ui/core';
import {Link, withRouter, useHistory} from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import {collapsedSidebarAction} from 'Store/Actions';
import SearchForm from './SearchForm';
import UserBlock from './UserBlock';
import Notifications from "./Notifications";

function Header(props) {

    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const isLogin = useSelector(state => state.authUser).isLogin;
    const history = useHistory();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    // function to change the state of collapsed sidebar
    const onToggleNavCollapsed = (event) => {
        const val = !settings.navCollapsed;
        dispatch(collapsedSidebarAction(val));
    }

    // open dashboard overlay
    const openDashboardOverlay = (e) => {
        var el = document.getElementsByClassName('dashboard-overlay')[0];
        el.classList.toggle("d-none");
        el.classList.toggle("show");
        if (el.classList.contains('show')) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
        e.preventDefault();
    }

    // close dashboard overlay
    const closeDashboardOverlay = () => {
        var e = document.getElementsByClassName('dashboard-overlay')[0];
        e.classList.remove('show');
        e.classList.add('d-none');
        document.body.style.overflow = "";
    }

    const handleLiveMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleClick = event => {
        setMenuAnchorEl(event.currentTarget);
    };

    const {horizontalMenu, agencyMenu, location} = props;
    return (
        <AppBar position="static" className="rct-header">
            <Toolbar className="d-flex justify-content-between w-100 pl-0">
                <div className="d-inline-flex align-items-center">
                    <ul className="list-inline mb-0 navbar-left">
                        <li className="list-inline-item mr-10" onClick={(e) => onToggleNavCollapsed(e)}>
                            <IconButton color="inherit" mini="true" aria-label="Menu" className="">
                                <MenuIcon/>
                            </IconButton>
                        </li>
                        <li className="list-inline-item search-icon d-inline-block mr-50">
                            <Link to="/" className="logo-normal">
                                {
                                    settings.darkMode ?
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/img/logo-dark.png?v=1.1`} className="img-fluid site-logo" alt="site-logo"/> :
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/img/logo-light.png?v=1.1`} className="img-fluid site-logo" alt="site-logo"/>
                                }
                            </Link>
                        </li>
                        <li className="list-inline-item search-icon d-inline-block ml-50">
                            <SearchForm/>
                        </li>
                    </ul>
                </div>
                {/*<ul className="navbar-right list-inline mb-0">*/}
                {/*     <li className="list-inline-item summary-icon">*/}
                {/*        <Tooltip title="Summary" placement="bottom">*/}
                {/*           <a href="!#" className="header-icon tour-step-3" onClick={(e) => openDashboardOverlay(e)}>*/}
                {/*              <i className="zmdi zmdi-info-outline"/>*/}
                {/*           </a>*/}
                {/*        </Tooltip>*/}
                {/*     </li>*/}
                {/*    <li className="list-inline-item">*/}
                {/*       <Tooltip title="Upgrade" placement="bottom">*/}
                {/*          <Button component={Link} to={`/${getAppLayout(location)}/pages/pricing`} variant="contained" className="upgrade-btn tour-step-4 text-white" color="primary">*/}
                {/*             <IntlMessages id="widgets.upgrade" />*/}
                {/*          </Button>*/}
                {/*       </Tooltip>*/}
                {/*    </li>*/}
                {/*     <LanguageProvider />*/}

                {/*    <Notifications/>*/}
                {/*</ul>*/}
                {
                    !isLogin &&
                    <ul className="navbar-right list-inline mb-0">
                        <li className="list-inline-item">
                            <Button component={Link} to={`/signin`} color="primary">
                                로그인
                            </Button>
                        </li>
                        {/* <li className="list-inline-item">
                            <Button component={Link} to={`/signup`} color="primary">
                                회원가입
                            </Button>
                        </li> */}
                    </ul>
                }
                {
                    isLogin &&
                    <ul className="navbar-right list-inline mb-0 d-flex align-items-center">
                        <li className="list-inline-item summary-icon">
                            <a className="live-icon" onClick={(event) => setMenuAnchorEl(event.currentTarget)}>
                                {/*<span className={'live-icon'}/>*/}
                                <img
                                    src={
                                        !settings.darkMode ?
                                            require('Assets/img/icon_live.png').default :
                                            require('Assets/img/icon_live_dark.png').default
                                    }
                                    className={'mb-1'}
                                    width={30}
                                />
                            </a>
                            <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleLiveMenuClose} style={{top: 30}}>
                                <MenuItem onClick={() => history.push(`/channel/vods?action=upload`)}>동영상 업로드</MenuItem>
                                <MenuItem onClick={() => history.push(`/live`)}>실시간 라이브 시작</MenuItem>
                            </Menu>
                        </li>
                        <Notifications/>
                        <li className="list-inline-item summary-icon mr-0" style={{fontSize: 23, paddingLeft: 7, color: '#aaaaaa', transform: 'rotate(90deg)'}}>
                            <i className="ti-line-dashed"/>
                        </li>
                        <UserBlock/>
                    </ul>
                }
            </Toolbar>
        </AppBar>
    );
}

export default withRouter(Header);
