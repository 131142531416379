/**
 * Search Form
 */
import React, {useState} from 'react'
import { Input } from 'reactstrap';
import {useHistory} from "react-router-dom";

function SearchForm(){
    const history = useHistory();
    const [searchTxt, setSearchTxt] = useState('');

    const handleKeyPress = (event) => {
        if(event.key === 'Enter') {
            history.push(`/videos/list?search=${searchTxt}`)
        }
    }

   return (
      <div className="search-wrapper">
         <Input
             type="search"
             className="search-input-lg"
             placeholder="검색어를 입력하세요"
             value={searchTxt}
             onChange={(e) => setSearchTxt(e.target.value)}
             onKeyPress={handleKeyPress}
         />
      </div>
   )
}

export default SearchForm