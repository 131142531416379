import React, {useState, useEffect} from 'react';
import {NotificationManager} from "react-notifications";
import {useHistory} from 'react-router-dom';
import ContentItem from "../videos/ContentItem";
import * as Apis from 'Api';

const Library = () => {
    const history = useHistory();
    const [historyCount, setHistoryCount] = useState(0)
    const [playlistCount, setPlaylistCount] = useState(0)
    const [likeCount, setLikeCount] = useState(0)
    const [historyList, setHistoryList] = useState([]);
    const [watchLaterList, setWatchLaterList] = useState([]);
    const [likeVideoList, setLikeVideoList] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        Apis.getUserFeedData().then((resp) => {
            setHistoryCount(resp.historyCount);
            setPlaylistCount(resp.playlistCount);
            setLikeCount(resp.likeCount);
            setHistoryList(resp.historyList);
            setWatchLaterList(resp.playlist);
            setLikeVideoList(resp.likeList);
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다');
        })
    }

    const onGoAllView = (link) => {
        history.push('/feed/playlist?list=' + link);
    }

    const renderBlock = (icon, title, desc, list, count, link) => {
        const showList = list.slice(0, 5);
        return (
            <div className={'library-block'}>
                <div className={'d-flex flex-row align-items-center justify-content-between'}>
                    <div className={'d-flex flex-row align-items-center'}>
                        <i className={icon + ' library-block-icon'}/>
                        <span className={'library-block-title'}>{title}</span>
                        <span className={'library-block-count'}>{count}</span>
                    </div>
                    <span className={'library-block-all'} onClick={() => onGoAllView(link)}>
                    모두보기
                    </span>
                </div>
                {
                    list.length === 0 ?
                        <div className={'library-block-desc'}>
                            {desc}
                        </div> :
                        <div className={'library-video-block'}>
                            {(showList.length>0) && showList.map((v) => <ContentItem content={v} key={v.id} onClick={() => history.push('/view/' + v.id)}/>)}
                        </div>
                }
            </div>
        )
    }

    return (
        <div className={'feed-library-container'}>
            <div className={'feed-library-content'}>
                {renderBlock('zmdi zmdi-time-restore', '기록', '시청한 동영상이 여기에 표시됩니다.', historyList, historyCount, 'hl')}
                <div className={'block-divider'}/>
                {renderBlock('zmdi zmdi-time', '나중에 볼 동영상', '동영상을 나중에 볼 동영상에 저장해보세요. 목록이 여기에 표시됩니다.', watchLaterList, playlistCount, 'wl')}
                <div className={'block-divider'}/>
                {renderBlock('zmdi zmdi-thumb-up', '좋아요 표시한 동영상', '마음에 드는 동영상에 좋아요 아이콘을 사용해 보세요. 목록이 여기에 표시됩니다.', likeVideoList, likeCount, 'll')}
            </div>
        </div>
    )
}

export default Library;