/**
 * Sidebar Content
 */
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {List, ListSubheader} from '@material-ui/core';
import {withRouter, useHistory} from 'react-router-dom';
import NavMenuItem from './NavMenuItem';
import ImgMenuItem from "./ImgMenuItem";
import {onSidebarSubscribes, onToggleMenu} from 'Store/Actions';
import * as Apis from 'Api';

function SidebarContent() {
    const dispatch = useDispatch();
    const history = useHistory();
    const sidebar = useSelector(state => state.sidebar);
    const {sidebarMenus} = sidebar;

    useEffect(() => {
        Apis.getSubscribeList().then((resp) => {
            dispatch(onSidebarSubscribes(resp));
        }).catch((e) => {

        });
    }, []);

    const toggleMenu = (menu, stateCategory) => {
        let data = {menu, stateCategory}
        dispatch(onToggleMenu(data));
    }

    if(
        history.location.pathname.indexOf('/channel/vods') !== 0 &&
        history.location.pathname.indexOf('/channel/setting') !== 0
    ) {
        return (
            <div className="rct-sidebar-nav">
                <nav className="navigation">
                    <List
                        className="rct-mainMenu p-0 m-0 list-unstyled mt-20 mb-10"
                    >
                        {sidebarMenus.mainCategory1.map((menu, key) => (
                            <NavMenuItem
                                menu={menu}
                                key={key}
                                onToggleMenu={() => toggleMenu(menu, 'category2')}
                            />
                        ))}
                    </List>
                    <div style={{height: 1, backgroundColor: '#44444433'}} />
                    <List
                        className="rct-mainMenu p-0 m-0 list-unstyled mt-10 mb-10"
                    >
                        {sidebarMenus.mainCategory2.map((menu, key) => (
                            <NavMenuItem
                                menu={menu}
                                key={key}
                                onToggleMenu={() => toggleMenu(menu, 'category2')}
                            />
                        ))}
                    </List>
                    <div style={{height: 1, backgroundColor: '#44444433'}} />
                    <List
                        className="rct-mainMenu p-0 m-0 list-unstyled mt-10 mb-10"
                        subheader={<ListSubheader className="side-title" component="li">구독</ListSubheader>}
                    >
                        {
                            sidebar.sidebarSubscribes.map((v) => (
                                <ImgMenuItem
                                    key={v.id}
                                    channelId={v.id}
                                    name={v.nickname}
                                    img={v.profile}
                                />
                            ))
                        }
                    </List>
                    <div style={{height: 1, backgroundColor: '#44444433'}} />
                    <List
                        className="rct-mainMenu p-0 m-0 list-unstyled mt-10 mb-10"
                    >
                        {sidebarMenus.mainCategory4.map((menu, key) => (
                            <NavMenuItem
                                menu={menu}
                                key={key}
                                onToggleMenu={() => toggleMenu(menu, 'category2')}
                            />
                        ))}
                    </List>
                </nav>
            </div>
        );
    } else {
        const bottomMenu = {
            "menu_title": "사용자설정",
            "menu_icon": "zmdi zmdi-settings",
            "path": "/channel/setting",
            "new_item": false,
            "child_routes": null
        };
        return (
            <div className="rct-sidebar-nav">
                <nav className="navigation">
                    <List
                        className="rct-mainMenu p-0 m-0 list-unstyled mt-20"
                    >
                        {sidebarMenus.channelCategory.map((menu, key) => (
                            <NavMenuItem
                                menu={menu}
                                key={key}
                                onToggleMenu={() => toggleMenu(menu, 'category2')}
                            />
                        ))}
                    </List>`
                </nav>
                <div>
                    <List
                        className="rct-mainMenu p-0 m-0 list-unstyled mb-20"
                    >
                        <NavMenuItem
                            menu={bottomMenu}
                            onToggleMenu={() => toggleMenu(bottomMenu, 'category2')}
                        />
                    </List>
                </div>
            </div>
        );
    }
}

export default withRouter(SidebarContent);
