import React, {useState, useEffect, useRef} from "react";
import {Input} from 'reactstrap';
import axios from 'axios';
import pako from 'pako';
import {useSelector} from "react-redux";
import SearchIcon from '@material-ui/icons/Search';
import TradingChart from "./TradingChart";
import * as Apis from 'Api';
import {NotificationManager} from "react-notifications";


function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const CoinChart = () => {
    const settings = useSelector(state => state.settings);
    const authUser = useSelector(state => state.authUser);
    const isLogin = authUser.isLogin;
    const basePriceList = useRef({});
    const foreignPriceList = useRef({});
    const krwUsdValue = useRef(1000);
    const btcUsdValue = useRef(0);
    const [symbolList, setSymbolList] = useState([]);
    const [baseCoinOption, setBaseCoinOption] = useState('UPBIT');
    const [foreignCoinOption, setForeignCoinOption] = useState('BINANCE:BTC');
    const [sortName, setSortName] = useState('symbol');
    const [sortAsc, setSortAsc] = useState(true);
    const [symbolFilter, setSymbolFilter] = useState('');
    const forceUpdate = useForceUpdate();

    const websocketInstance = useRef({base: null, foreign: null});

    useEffect(() => {
        getKRWUSDT();
        const intervalId = setInterval(() => {
            forceUpdate();
        }, 1000);
        return () => {
            if (websocketInstance.current.base && websocketInstance.current.base.readyState !== WebSocket.CLOSED) websocketInstance.current.base.close();
            if (websocketInstance.current.foreign && websocketInstance.current.foreign.readyState !== WebSocket.CLOSED) websocketInstance.current.foreign.close();
            clearInterval(intervalId);
        }
    }, []);

    useEffect(() => {
        if (websocketInstance.current.base && websocketInstance.current.base.readyState !== WebSocket.CLOSED) websocketInstance.current.base.close();
        if (websocketInstance.current.foreign && websocketInstance.current.foreign.readyState !== WebSocket.CLOSED) websocketInstance.current.foreign.close();
        basePriceList.current = {};
        foreignPriceList.current = {};
        setSymbolList([]);
        switch (baseCoinOption) {
            case 'COINONE':
                getCoinoneSymbolList();
                break;
            case 'UPBIT':
                getUpbitSymbolList();
                break;
            case 'BITHUMB':
                getBithumbSymbolList();
                break;
        }
    }, [baseCoinOption, foreignCoinOption]);

    useEffect(() => {
        if (symbolList.length !== 0) {
            basePriceList.current = {};
            if (websocketInstance.current.base && websocketInstance.current.base.readyState !== WebSocket.CLOSED) websocketInstance.current.base.close();
            switch (baseCoinOption) {
                case 'COINONE':
                    getCoinoneData();
                    break;
                case 'UPBIT':
                    getUpbitData();
                    break;
                case 'BITHUMB':
                    getBithumbData();
                    break;
            }

            if (websocketInstance.current.foreign && websocketInstance.current.foreign.readyState !== WebSocket.CLOSED) websocketInstance.current.foreign.close();
            switch (foreignCoinOption) {
                case 'BINANCE:BTC':
                case 'BINANCE:USDT':
                case 'BINANCE_FUTURES':
                    getBinaceData();
                    break;
                case 'HUOBI':
                    getHuobiData();
                    break;
            }
        }
    }, [symbolList]);

    const getKRWUSDT = () => {
        axios.get('https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD').then((resp) => {
            krwUsdValue.current = Number(resp.data[0].basePrice);
        }).catch((e) => {
            console.error(e);
        })
    }

    const getCoinoneSymbolList = () => {
        Promise.all([
            Apis.proxyGetUrl('https://tb.coinone.co.kr/api/v1/coin'),
            axios.get('https://api.coingecko.com/api/v3/search?locale=kr'),
        ]).then(([coinoneCoins, allCoinsResp]) => {
            const allCoins = allCoinsResp.data.coins;
            const data = coinoneCoins.coins.map((v) => {
                const info = allCoins.find((vv) => (vv.symbol === v.symbol));
                return {
                    name: v.name_kr,
                    symbol: v.symbol,
                    symbolImageUrl: info && info.thumb,
                }
            });
            setSymbolList(data);
        }).catch((e) => {
            console.log(e);
        });
    }

    const getBithumbSymbolList = () => {
        Promise.all([
            axios.get("https://api.bithumb.com/public/ticker/all_krw"),
            axios.get('https://api.coingecko.com/api/v3/search?locale=kr'),
        ]).then(([bitthumbCoinsResp, allCoinsResp]) => {
            const allCoins = allCoinsResp.data.coins;
            const bitthumbCoins = bitthumbCoinsResp.data.data;
            const data = Object.keys(bitthumbCoins).map((v) => {
                const info = allCoins.find((vv) => ("SOC" !== vv.symbol || "soda-coin" === vv.id) && (("RAI" !== vv.symbol || "rai-finance" === vv.id) && vv.symbol === v));
                return {
                    name: info && info.name,
                    symbol: v,
                    symbolImageUrl: info && info.thumb,
                    price: Number(bitthumbCoins[v].closing_price),
                    volume: Number(bitthumbCoins[v].acc_trade_value_24H),
                    lowestPrice: Number(bitthumbCoins[v].min_price),
                    highestPrice: Number(bitthumbCoins[v].max_price),
                    changePrice: Number(bitthumbCoins[v].closing_price) - Number(bitthumbCoins[v].prev_closing_price),
                    changeRate: Number(bitthumbCoins[v].closing_price) / Number(bitthumbCoins[v].prev_closing_price) - 1
                }
            });
            setSymbolList(data);
        }).catch((e) => {
            console.log(e);
        });
    }

    const getUpbitSymbolList = () => {
        axios.get('https://api.upbit.com/v1/market/all').then((resp) => {
            const data = resp.data.filter((v) => (/^KRW-/.test(v.market))).map((v) => ({
                name: v.korean_name,
                symbol: v.market.substr(4),
                symbolImageUrl: 'https://static.upbit.com/logos/' + v.market.substr(4) + '.png'
            }));
            setSymbolList(data);
        }).catch((e) => {
            console.log(e);
        });
    }

    const getCoinoneData = () => {
        websocketInstance.current.base = new WebSocket('wss://wss.coinone.co.kr/ws?token_type=web');
        websocketInstance.current.base.onopen = (e) => {
            e.target.send(JSON.stringify({
                channel: "ticker_all",
                event: "subscribe",
                market: "krw"
            }))
        }
        websocketInstance.current.base.onmessage = (e) => {
            const wsData = JSON.parse(e.data);
            // const coinData = wsData.data.map((v) => ({
            //     symbol: v.trading_pair.split("-")[0],
            //     price: Number(v.last),
            //     volume: Number(v.base_volume),
            //     lowestPrice: Number(v.low),
            //     highestPrice: Number(v.high),
            //     changeRate: Number(v.change_rate) / 100,
            //     changePrice: Number(v.change)
            // }));
            if (wsData.data) {
                const priceData = wsData.data.reduce((accumulator, v) => {
                    accumulator[v.trading_pair.replace('-KRW', '')] = {
                        price: Number(v.last),
                        volume: Number(v.base_volume),
                        lowestPrice: Number(v.low),
                        highestPrice: Number(v.high),
                        changeRate: Number(v.change_rate) / 100,
                        changePrice: Number(v.change)
                    };
                    return accumulator;
                }, {});
                basePriceList.current = priceData;
            }
        }
    }

    const getBithumbData = () => {
        websocketInstance.current.base = new WebSocket('wss://pubwss.bithumb.com/pub/ws');
        websocketInstance.current.base.onopen = (e) => {
            const target = e.target;
            target.send(JSON.stringify({
                type: "ticker",
                symbols: symbolList.map((function (e) {
                        return "".concat(e.symbol, "_KRW")
                    }
                )),
                tickTypes: ["24H", "MID"]
            }));
            setTimeout((function () {
                    target.send(JSON.stringify({
                        type: "transaction",
                        symbols: symbolList.map((function (e) {
                                return "".concat(e.symbol, "_KRW")
                            }
                        ))
                    }))
                }
            ), 50)
        }
        websocketInstance.current.base.onmessage = (e) => {
            const wsData = JSON.parse(e.data);
            if (wsData.type === 'ticker') {
                const symbol = wsData.content.symbol.replace('_KRW', '');
                if (wsData.content.tickType === '24H') {
                    basePriceList.current[symbol] = basePriceList.current[symbol] || {};
                    basePriceList.current[symbol] = {
                        ...basePriceList.current[symbol],
                        volume: Number(wsData.content.value)
                    }
                }
                if (wsData.content.tickType === 'MID') {
                    basePriceList.current[symbol] = {
                        volume: wsData.content.volume,
                        price: Number(wsData.content.closePrice),
                        changeRate: Number(wsData.content.chgRate) / 100,
                        changePrice: Number(wsData.content.chgAmt),
                        lowestPrice: Number(wsData.content.lowPrice),
                        highestPrice: Number(wsData.content.highPrice)
                    }
                }
                // setBasePriceList({...basePriceList});
            }
        }
    }

    const getUpbitData = () => {
        websocketInstance.current.base = new WebSocket('wss://api.upbit.com/websocket/v1');
        websocketInstance.current.base.onopen = (e) => {
            e.target.send(JSON.stringify([{
                ticket: btoa("kimp.ga-".concat((new Date).getTime()))
            }, {
                type: "ticker",
                codes: symbolList.map((function (e) {
                        return "KRW-".concat(e.symbol)
                    }
                ))
            }, {
                format: "SIMPLE"
            }]))
        }
        websocketInstance.current.base.onmessage = (e) => {
            const reader = new FileReader();
            reader.onload = () => {
                const wsData = JSON.parse(reader.result);
                const symbol = wsData.cd.replace('KRW-', '');
                basePriceList.current[symbol] = {
                    price: wsData.tp,
                    volume: wsData.atp24h,
                    changePrice: wsData.scp,
                    changeRate: wsData.scr,
                    lowestPrice: wsData.l52wp,
                    highestPrice: wsData.h52wp,
                    caution: "CAUTION" === wsData.mw
                }
                // setBasePriceList({...basePriceList});
            };
            reader.readAsText(e.data);
        }
    }

    const getBinaceData = () => {
        const wsUrlParam = symbolList.map((v) => {
            if (v.symbol === 'BTC') {
                return 'btcusdt@miniTicker';
            } else {
                return foreignCoinOption === 'BINANCE:BTC' ? (v.symbol.toLowerCase() + "btc@miniTicker") : (v.symbol.toLowerCase() + "usdt@miniTicker")
            }
        }).join('/');

        const wsUrl = foreignCoinOption !== 'BINANCE_FUTURES' ? 'wss://stream.binance.com:9443/ws/' + wsUrlParam : 'wss://fstream.binance.com/stream?streams=' + wsUrlParam;

        // const wsUrlParma = symbolList.map((v) => foreignCoinOption === 'BINANCE_FUTURES' ?
        //     ("BTC" === v.symbol ? "btcusdt@miniTicker" : "".concat(v.symbol.toLowerCase(), "usdt@miniTicker")) :
        //     ("BTC" === v.symbol ? ["btcusdt@miniTicker"] : `${v.symbol.toLowerCase()}btc@miniTicker/${v.symbol.toLowerCase()}usdt@miniTicker`)
        // ).join('/');

        websocketInstance.current.foreign = new WebSocket(wsUrl);
        websocketInstance.current.foreign.onmessage = (e) => {
            let wsData = e.data ? JSON.parse(e.data) : [];
            wsData = wsData.data ? wsData.data : wsData;
            if (wsData.s === 'BTCUSDT') {
                btcUsdValue.current = wsData.c;
            }
            if (foreignCoinOption === 'BINANCE:BTC' && btcUsdValue.current === 0) return;

            const symbol = wsData.s.replace(/BTC$/, "").replace(/USD.?$/, "");
            const multiValue = (foreignCoinOption === 'BINANCE:BTC' && symbol !== 'BTC') ? krwUsdValue.current * btcUsdValue.current : krwUsdValue.current;
            if (foreignCoinOption)
                foreignPriceList.current[symbol] = {
                    price: Number((Number(wsData.c) * multiValue).toFixed(2)),
                    volume: wsData.v * multiValue * ((foreignCoinOption === 'BINANCE:BTC' && symbol === 'BTC') ? btcUsdValue.current : 1),
                    lowestPrice: wsData.l * multiValue,
                    highestPrice: wsData.h * multiValue,
                }
        }
    }

    const getHuobiData = () => {
        websocketInstance.current.foreign = new WebSocket('wss://api-aws.huobi.pro/ws');
        websocketInstance.current.foreign.onopen = (e) => {
            e.target.send(JSON.stringify({
                sub: "market.overview"
            }))
        }
        websocketInstance.current.foreign.onmessage = (e) => {
            const reader = new FileReader();
            reader.onload = () => {
                const binaryData = new Uint8Array(reader.result);
                const wsData = JSON.parse(pako.inflate(binaryData, {to: 'string'}));
                if (wsData.ping) {
                    e.target.send(JSON.stringify({pong: wsData.ping}))
                } else if (wsData.data) {
                    wsData.data.filter((e) => /usdt$/.test(e.symbol)).forEach((v) => {
                        const symbol = v.symbol.replace('usdt', '').toUpperCase();
                        foreignPriceList.current[symbol] = {
                            price: v.close * krwUsdValue.current,
                            volume: v.vol * krwUsdValue.current,
                            lowestPrice: wsData.low * krwUsdValue.current,
                            highestPrice: wsData.high * krwUsdValue.current,
                        };
                    });
                }
            };
            reader.readAsArrayBuffer(e.data);
        }
    }

    const onChangeOrder = (value) => {
        if (sortName === value) {
            setSortAsc(!sortAsc);
        } else {
            setSortName(value);
            setSortAsc(true);
        }
    }

    const convNumForPoint = (val) => {
        if (!val || isNaN(val)) return '';
        if (val > 1000) return Number(val.toFixed(0)).toLocaleString();
        if (val > 1) return val.toFixed(2);
        return val.toFixed(4);
    }

    const onSaveCoinInfo = (data) => {
        if(isLogin) {
            Apis.saveCoinInfoAdd(data).then(() => {
                NotificationManager.success('현재 코인정보가 저장되었습니다.');
            }).catch((e) => {
                NotificationManager.error('오류가 발생했습니다.');
            });
        }
    }

    function numberToKorean(number) {
        const inputNumber = number < 0 ? false : number;
        const unitWords = ['', '만', '억', '조', '경'];
        const splitUnit = 10000;
        const splitCount = unitWords.length;
        const resultArray = [];
        let resultString = '';

        for (let i = 0; i < splitCount; i++) {
            let unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
            unitResult = Math.floor(unitResult);
            if (unitResult > 0) {
                resultArray[i] = unitResult;
            }
        }

        for (var i = 0; i < resultArray.length; i++) {
            if (!resultArray[i]) continue;
            resultString = String(resultArray[i]) + unitWords[i] + resultString;
        }

        return resultString;
    }

    let tableData = Object.keys(basePriceList.current).map((v) => {
        const baseVal = basePriceList.current[v];
        const foreignVal = foreignPriceList.current[v] || {};
        return {
            symbol: v,
            symbolName: baseVal.name,
            symbolImageUrl: `https://static.upbit.com/logos/${v}.png`,
            kimpVal: Number(baseVal.price) - Number(foreignVal.price),
            kimpPercent: (Number(baseVal.price) - Number(foreignVal.price)) * 100 / Number(foreignVal.price),
            price: Number(baseVal.price),
            foreignPrice: Number(foreignVal.price),
            volume: Number(baseVal.volume),
            foreignVolume: Number(foreignVal.volume),
            lowestPrice: Number(baseVal.lowestPrice),
            foreignLowestPrice: Number(foreignVal.lowestPrice),
            highestPrice: Number(baseVal.highestPrice),
            foreignHighestPrice: Number(foreignVal.highestPrice),
            changePrice: Number(baseVal.changePrice),
            changeRate: Number(baseVal.changeRate),
        }
    });

    tableData.sort((a, b) => {
        if(a[sortName] === b[sortName]) return 0;
        if(!a[sortName]) return 1;
        if(!b[sortName]) return -1;
        if(a[sortName] > b[sortName]) return sortAsc ? 1 : -1;
        if(a[sortName] < b[sortName]) return sortAsc ? -1 : 1;
    })

    return (
        <div className={'coin-chart-container'}>
            <TradingChart/>
            <div className={'d-flex flex-row justify-content-between fs-12 site-chart-filter mt-30'}>
                <div className={'d-flex flex-row align-items-center'}>
                    <span>기준거래소</span>
                    <div className={'ml-1'}>
                        <Input
                            className={'fs-12'}
                            type={'select'}
                            value={baseCoinOption}
                            onChange={(e) => setBaseCoinOption(e.target.value)}
                        >
                            <option value={'UPBIT'}>업비트</option>
                            <option value={'BITHUMB'}>빗썸</option>
                            <option value={'COINONE'}>코인원</option>
                        </Input>
                    </div>
                    <i className="zmdi zmdi-swap fs-17 ml-2 mr-2"/>
                    <div className={'mr-1'}>
                        <Input
                            type={'select'}
                            className={'fs-12'}
                            value={foreignCoinOption}
                            onChange={(e) => setForeignCoinOption(e.target.value)}
                        >
                            <option value={'BINANCE:BTC'}>바이낸스 BTC 마켓</option>
                            <option value={'BINANCE:USDT'}>바이낸스 USDT 마켓</option>
                            <option value={'BINANCE_FUTURES'}>바이낸스 선물 USDⓈ-M 마켓</option>
                            <option value={'HUOBI'}>후오비 글로벌 USDT 마켓</option>
                        </Input>
                    </div>
                    <span>해외 거래소</span>
                </div>
                <div className={'d-flex flex-row align-items-center'}>
                    <span className={'mr-1'}>총 {tableData.length}개</span>
                    <div className={'site-coin-filter-input'}>
                        <Input
                            type={'text'}
                            value={symbolFilter}
                            onChange={(e) => setSymbolFilter(e.target.value)}
                        />
                        <SearchIcon />
                    </div>
                </div>
            </div>
            <div className="table-responsive fs-12 mt-30 site-chart-price">
                <table className="table table-hover">
                    <thead>
                    <tr>
                        {
                            [
                                {value: 'symbol', label: '이름'},
                                {value: 'price', label: '현재가'},
                                {value: 'kimpPercent', label: '김프'},
                                {value: 'changePrice', label: '전일대비'},
                                {value: 'highestPrice', label: '고가대비'},
                                {value: 'lowestPrice', label: '저가대비'},
                                {value: 'volume', label: '거래액'},
                            ].map((v) => (
                                <th className={'text-center'} key={v.value} onClick={() => onChangeOrder(v.value)}>
                                    {v.label}
                                    <span className={'sort-icon'}>
                                        <i className={"zmdi zmdi-caret-up " + (sortName === v.value && sortAsc ? 'active' : '')}/>
                                        <i className={"zmdi zmdi-caret-down " + (sortName === v.value && !sortAsc ? 'active' : '')}/>
                                    </span>
                                </th>
                            ))
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        tableData.length === 0 ?
                            <tr>
                                <td colSpan={6} className={'text-center ' + (settings.darkMode ? 'text-white' : 'text-black')}>로딩중...</td>
                            </tr> :
                            tableData.filter((v) => symbolFilter.length === 0 || v.symbol.toLowerCase().indexOf(symbolFilter.toLowerCase()) !== -1).map((v) => (
                                <tr key={v.symbol} onClick={() => onSaveCoinInfo(v)}>
                                    <td className={'text-center'}>
                                        <span>{v.symbol}</span><br/>
                                        <img src={`https://static.upbit.com/logos/${v.symbol}.png`} className={'symbol-img'}/>
                                    </td>
                                    <td className={'text-center'}>
                                        <span>{convNumForPoint(v.price)}</span><br/>
                                        {
                                            v.foreignPrice ?
                                                <span>{convNumForPoint(v.foreignPrice)}</span> :
                                                <span>&nbsp;</span>
                                        }
                                    </td>
                                    <td className={'text-center'}>
                                        <span>{convNumForPoint(v.kimpVal)}</span><br/>
                                        <span className={Number(v.kimpPercent) > 0 ? 'text-darkgreen' : 'text-red'}>
                                            {(Number(v.kimpPercent) > 0 ? '+' : '') + convNumForPoint(v.kimpPercent)}{v.kimpPercent ? '%' : ''}
                                        </span>
                                    </td>
                                    <td className={'text-center ' + (Number(v.changeRate) > 0 ? 'text-darkgreen' : 'text-red')}>
                                        {(Number(v.changeRate) > 0 ? '+' : '') + convNumForPoint(v.changeRate)}
                                    </td>
                                    <td className={'text-center'}>
                                        <span>{convNumForPoint(v.lowestPrice)}</span><br/>
                                        {
                                            v.foreignLowestPrice ?
                                                <span>{convNumForPoint(v.foreignLowestPrice)}</span> :
                                                <span>&nbsp;</span>
                                        }
                                    </td>
                                    <td className={'text-center'}>
                                        <span>{convNumForPoint(v.highestPrice)}</span><br/>
                                        {
                                            v.foreignHighestPrice ?
                                                <span>{convNumForPoint(v.foreignHighestPrice)}</span> :
                                                <span>&nbsp;</span>
                                        }
                                    </td>
                                    <td className={'text-center'}>
                                        <span>{numberToKorean(v.volume)}</span><br/>
                                        {
                                            v.foreignVolume ?
                                                <span>{convNumForPoint(v.foreignVolume)}</span> :
                                                <span>&nbsp;</span>
                                        }
                                    </td>
                                </tr>
                            ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CoinChart;