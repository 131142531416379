/**
 * Chat Area Component
 */
import React, {useState, useRef, useEffect} from 'react';
import {FormGroup, Input} from 'reactstrap';
import {Scrollbars} from 'react-custom-scrollbars';
import {useDispatch, useSelector} from 'react-redux';
import {Button, IconButton, Menu, MenuItem, CircularProgress} from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views';
import socketIOClient from 'socket.io-client';
import {withRouter} from 'react-router-dom';
import Avatar from "Components/Widgets/Avatar";
import MessageBlock from 'Components/Chat/MessageBlock';
import ChatEmojiPicker from 'Components/Chat/ChatEmojiPicker';
import * as Apis from 'Api';

function HomeChat({chatId}) {
    const chatScroll = useRef();
    const authUser = useSelector(state => state.authUser);
    const isLogin = authUser.isLogin;
    const userInfo = authUser.userInfo;
    const [socket, setSocket] = useState(null);
    const [message, setMessage] = useState('')
    const [previousChats, setPreviousChats] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setSocket(socketIOClient(Apis.wsChatAddress + chatId, {
            transports: ["websocket"]
        }));
        return () => {
            if (socket) {
                socket.disconnect();
            }
        }
    }, []);

    useEffect(() => {
        if (!socket) return;
        socket.on('connect', handleSocketConnect);
        socket.on('SRV_PREVIOUS_CHAT_LIST', handleLoadPreviousChat);
        socket.on('SRV_NEW_CHAT_MESSAGE', handleAddChatMsg);
        socket.on('SRV_SEND_POINT', handleAddPointMsg);
        return () => {
            socket.off('connect', handleSocketConnect);
            socket.off('SRV_PREVIOUS_CHAT_LIST', handleLoadPreviousChat);
            socket.off('SRV_NEW_CHAT_MESSAGE', handleAddChatMsg);
            socket.off('SRV_SEND_POINT', handleAddPointMsg);
        };
    }, [socket]);

    const handleSocketConnect = () => {
        setLoading(false);
        socket.emit('CLIENT_AUTHENTICATION', Apis.accessToken());
    }

    const handleLoadPreviousChat = (data) => {
        data.forEach((v) => {
            v.isMy = userInfo.id === v.userId
        });
        setPreviousChats(data);
        chatScroll.current.scrollToBottom();
    }

    const handleAddChatMsg = (data) => {
        data.isMy = userInfo.id === data.userId;
        setPreviousChats(previousChats => [
            ...previousChats,
            data
        ]);
        chatScroll.current.scrollToBottom();
    }

    const handleAddPointMsg = (data) => {
        console.log(data);
        data.isMy = userInfo.id === data.userId;
        setPreviousChats(previousChats => [
            ...previousChats,
            data
        ]);
        chatScroll.current.scrollToBottom();
    }

    const onSendMessage = () => {
        if (message !== '') {
            let data = {
                isMy: true,
                type: 'chat',
                message: message,
                nickname: userInfo.nickname,
                profile: userInfo.profile,
                time: new Date(),
            }
            previousChats.push(data);
            setPreviousChats([...previousChats]);
            socket.emit('CLIENT_NEW_CHAT_MESSAGE', message);
            setMessage('');
            setTimeout(() => {
                chatScroll.current.scrollToBottom();
            }, 200);
        }
    }

    const onSendPoint = (point, message) => {
        let data;
        if (userInfo.userPoint && userInfo.userPoint > point) {
            data = {
                isMy: true,
                type: 'point',
                message,
                nickname: userInfo.nickname,
                profile: userInfo.profile,
                time: new Date(),
                point
            };
            socket.emit('CLIENT_SEND_POINT', {point, message});
        } else {
            data = {
                isMy: true,
                type: 'point',
                nickname: userInfo.nickname,
                profile: userInfo.profile,
                time: new Date(),
                point: -1       // point does not enough!
            }
        }
        previousChats.push(data);
        setPreviousChats([...previousChats]);
        setTimeout(() => {
            chatScroll.current.scrollToBottom();
        }, 200);
    }

    // const renderChatComponent = () => (
    return (
        <div className={'d-flex flex-column flex-grow-1'}>
            <Scrollbars
                className="chat-body-wrapper"
                autoHide
                ref={chatScroll}
            >
                {
                    loading ?
                        <div className={'d-flex justify-content-center align-items-center'} style={{height: '100%'}}>
                            <CircularProgress/>
                        </div> :
                        <div className="chat-body p-10">
                            {previousChats.map((previousChat, key) => (
                                <MessageBlock
                                    even={!previousChat.isMy}
                                    key={key}
                                    selectedUserPhotoUrl={previousChat.profile}
                                    data={previousChat}
                                    showTimeStamp={false}
                                />
                            ))}
                        </div>
                }
            </Scrollbars>
            {
                !isLogin ?
                    <div className="chat-footer d-flex flex-column">
                        <div className={'chat-login-msg'}>
                            채팅을 하려면 로그인을 해야 합니다
                        </div>
                    </div> :
                    <div className="chat-footer d-flex flex-column">
                        <div>
                            <div className={'d-flex pl-1 align-items-center pb-1 pt-1'}>
                                <div className="mr-3 w-100">
                                    <Input
                                        type="text"
                                        id="search-msg"
                                        placeholder="메시지 입력하기"
                                        value={message}
                                        className="msg-input"
                                        autoComplete={"off"}
                                        onChange={(event) => setMessage(event.target.value)}
                                        onKeyDown = {(e) => {
                                            if (e.key === "Enter"){
                                                onSendMessage();
                                            }        
                                        }}
                                    />
                                </div>
                                <IconButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() => onSendMessage()}
                                    className="bg-primary"
                                >
                                    <i className="zmdi zmdi-mail-send ml-1 fs-14 text-white"/>
                                </IconButton>
                            </div>
                            {/*<div className={'chat-tools d-flex flex-column pb-2'}>*/}
                            {/*    {*/}
                            {/*        showEmojPicker && <ChatEmojiPicker onSelect={(emoji) => setMessage(message + emoji.native)}/>*/}
                            {/*    }*/}
                            {/*    <div className={'d-flex flex-row'}>*/}
                            {/*        <IconButton className="text-white" onClick={() => setShowEmojPicker(!showEmojPicker)}>*/}
                            {/*            <i className="zmdi zmdi-mood text-white"/>*/}
                            {/*        </IconButton>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
            }
        </div>
    )
}

export default withRouter(HomeChat);
