import React, {useEffect, useState, useRef} from 'react';
import {Helmet} from "react-helmet";
import {useDispatch} from "react-redux";
import {FormControlLabel, Checkbox} from '@material-ui/core';
import NotificationManager from "react-notifications";
import {useLocation, useHistory} from "react-router-dom";
import moment from 'moment';
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';
import PaginationComponent from "Components/PaginationComponent";
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';
import DeleteConfirmationDialog from 'Components/DeleteConfirmationDialog/DeleteConfirmationDialog';
import VodUploadModal from './VodUploadModal';
import {collapsedSidebarAction} from "Store/Actions";
import * as Apis from 'Api';


const VodUpload = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const searchParams = new URLSearchParams(useLocation().search);
    const [loading, setLoading] = useState(false);
    const [editVodId, setEditVodId] = useState(searchParams.get('action') === "upload" ? '' : undefined);
    const [vodList, setVodList] = useState([]);
    const [deleteItemId, setDeleteItemId] = useState();
    const deleteConfirmationDialogRef = useRef();


    useEffect(() => {
        dispatch(collapsedSidebarAction(false));
        getData();
    }, []);


    const getData = () => {
        Apis.getMyVodList(1).then((resp) => {
            setVodList(resp);
            setLoading(false);
        }).catch((e) => {

        });
    }

    const onDelete = (id) => {
        deleteConfirmationDialogRef.current.open();
        setLoading(true)
        setDeleteItemId(id);
        setTimeout(function(){
            setLoading(false)
        },1000)
        
    }

    const deleteItem = () => {
        deleteConfirmationDialogRef.current.close();
        Apis.deleteMyVod(deleteItemId).then((resp) => {
            getData();
        }).catch((e) => {
            NotificationManager.error('삭제중 오류가 발생했습니다.');
        });
    }

    const onEdit = (id) => {
        setEditVodId(id);
    }

    return (
        <div className={'vod-container p-10'}>
            <Helmet>
                <title>채널 콘텐츠</title>
                <meta name="description" content="vod management"/>
            </Helmet>
            <PageTitleBar title={"채널 콘텐츠"}/>
            <RctCollapsibleCard fullBlock>
                <div className="table-responsive">
                    <div className="d-flex justify-content-between py-20 px-10 border-bottom">
                        <div>
                            <a onClick={getData} className="btn-outline-default mr-10"><i className="ti-reload"/></a>
                        </div>
                        <div>
                            <a onClick={(e) => setEditVodId('')} color="primary" className="caret btn-sm mr-10">동영상추가 <i className="zmdi zmdi-plus"/></a>
                        </div>
                    </div>
                    <table className="table table-middle table-hover mb-0">
                        <thead>
                        <tr className={'text-center'}>
                            <th className="w-5">
                                <FormControlLabel
                                    className={'mb-0'}
                                    control={
                                        <Checkbox
                                            indeterminate={false}
                                            checked={false}
                                            onChange={(e) => null}
                                            value="all"
                                            color="primary"
                                        />
                                    }
                                    label=""
                                />
                            </th>
                            <th>동영상</th>
                            <th>이름</th>
                            <th>날짜</th>
                            <th>조회수</th>
                            <th>관리</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            vodList.map((vod) => (
                                <tr className={'text-center'} key={vod.id}>
                                    <td>
                                        <FormControlLabel
                                            className={'mb-0'}
                                            control={
                                                <Checkbox
                                                    checked={false}
                                                    onChange={() => null}
                                                    color="primary"
                                                />
                                            }
                                        />
                                    </td>
                                    <td>
                                        <img
                                            src={vod.thumbnail ? vod.thumbnail : `${process.env.PUBLIC_URL}/assets/images/img/no-image.png`}
                                            alt=''
                                            width={120}
                                            style={{maxHeight: 67, objectFit: 'contain', cursor:'pointer'}}
                                            onClick={() => history.push('/view/' + vod.id)}
                                        />
                                    </td>
                                    <td>
                                        <div className="media">
                                            <div className="media-body">
                                                <h5 className="mb-5 fw-bold text-left">{vod.name}</h5>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{moment(vod.createdAt).format('YYYY.MM.DD')}</td>
                                    <td>
                                        <span className="small">{vod.visit}</span>
                                    </td>
                                    <td className="list-action">
                                        <button type="button" className="rct-link-btn" onClick={() => onEdit(vod.id)}><i className="ti-pencil"/></button>
                                        <button type="button" className="rct-link-btn" onClick={() => onDelete(vod.id)}><i className="ti-close"/></button>
                                    </td>
                                </tr>
                            ))
                        }

                        </tbody>
                        <tfoot className="border-top">
                        <tr>
                            <td colSpan="100%">
                                {/*<PaginationComponent*/}
                                {/*    totalItems={200}*/}
                                {/*    pageSize={10}*/}
                                {/*    onSelect={() => null}*/}
                                {/*    maxPaginationNumbers={7}*/}
                                {/*    activePage={10}*/}
                                {/*/>*/}
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
                {loading &&
                <RctSectionLoader/>
                }
            </RctCollapsibleCard>
            {
                editVodId !== undefined && <VodUploadModal show={true} vodId={editVodId} onClose={() => {setEditVodId(undefined); getData();}} onFinish={getData} />
            }
            <DeleteConfirmationDialog
                ref={deleteConfirmationDialogRef}
                title="이 동영상을 완전히 삭제하시겠습니까?"
                message="삭제된 동영상은 되돌릴수없습니다."
                onConfirm={() => deleteItem()}
            />
        </div>
    )
}

export default VodUpload;