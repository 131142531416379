/**
 * App.js Layout Start Here
 */
import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';
import RctThemeProvider from './RctThemeProvider';
import AppSignIn from './Signin';
// import AppSignUp from './Signup';
import {
    AsyncTermsConditionComponent
} from 'Components/AsyncComponent/AsyncComponent';
import Auth from 'Auth/Auth';
import Callback from "Components/Callback/Callback";
import PrivateRoute from './PrivateRoute';
import RctAppLayout from 'Components/RctAppLayout';
import Videos from 'Routes/videos';
import View from 'Routes/view';
import Live from 'Routes/live';
import FeedRoute from 'Routes/feed';
import ChannelRoute from 'Routes/channel'
import SiteRoute from 'Routes/site'
import SigninToken from 'Routes/signin'


//Auth0 Handle Authentication
const auth = new Auth();
const handleAuthentication = ({location}) => {
    if (/access_token|id_token|error/.test(location.hash)) {
        auth.handleAuthentication();
    }
}
/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */

function App(props) {
    const authUser = useSelector(state => state.authUser);
    const {user} = authUser;
    const {location, match} = props;
    if (location.pathname === '/') {
        return (<Redirect to={'/videos'}/>);
    }
    return (
        <RctThemeProvider>
            <NotificationContainer/>
            <Route path="/signintoken/:token" component={SigninToken} />
            <Route path="/videos/" component={Videos}/>
            <Route path="/view/:videoId" component={View}/>
            <PrivateRoute path="/live" component={Live}/>
            <PrivateRoute path={'/channel'} component={ChannelRoute} />
            <Route path={'/feed'} component={FeedRoute} />
            <Route path="/signin" component={AppSignIn}/>
            {/* <Route path="/signup" component={AppSignUp}/> */}

            <Route path="/terms-condition" component={AsyncTermsConditionComponent}/>
            <Route path="/callback" render={(props) => {
                handleAuthentication(props);
                return <Callback {...props} />
            }}/>
        </RctThemeProvider>
    );
}

export default App;
