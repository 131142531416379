import React from 'react';
import {Picker} from "emoji-mart";

const ChatEmojiPicker = ({onSelect}) => {

    return (
        <div className={'emoji-picker'}>
            <Picker
                native={true}
                theme={'dark'}
                showPreview={false}
                emojiSize={24}
                i18n={{
                    categories: {
                        search: '검색결과',
                        recent: '최근에 사용한 항목',
                        smileys: '웃는 얼굴',
                        people: '피플',
                        nature: '동물 및 자연',
                        foods: '음식 및 식물',
                        activity: '활동',
                        places: '교통 및 장소',
                        objects: '물건',
                        symbols: '기호',
                        flags: '깃발',
                        custom: '커스텀',
                    }
                }}
                onSelect={(v) => onSelect(v)}
            />
        </div>
    )
}

export default ChatEmojiPicker;
