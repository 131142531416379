import React, {useState, useEffect} from "react";
import {useHistory} from 'react-router-dom';
import RctAppLayout from "Components/RctAppLayout";
import ContentItem from "../../videos/ContentItem";
import * as Apis from 'Api';
import Avatar from "Components/Widgets/Avatar";
import {Button} from "@material-ui/core";

const ChannelPage = ({channelId}) => {
    const history = useHistory();
    const [isSubscribe, setIsSubscribe] = useState(false);
    const [channelInfo, setChannelInfo] = useState({});
    const [videoList, setVideoList] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        Apis.getChannelVideos(channelId).then((resp) => {
            setIsSubscribe(resp.isSubscribe);
            setChannelInfo(resp.channelInfo);
            setVideoList(resp.videoList);
        }).catch((e) => {
            console.error(e);
        });
    }

    const onSubscribeChannel = () => {
        Apis.setSubscribe(channelId).then((resp) => {
            setIsSubscribe(!isSubscribe);
        }).catch((e) => {
            console.error(e);
        });
    }

    return (
        <div className={'home-container pl-70 pr-70'}>
            <div className={'channel-content d-flex flex-row justify-content-between align-items-center mb-50'}>
                <div className={'d-flex flex-row'}>
                    <Avatar alt="user profile" src={channelInfo.profile} name={channelInfo.nickname} size={80} className="img-fluid rounded-circle ml-15 align-self-start"/>
                    <div className={'d-flex flex-column ml-30 justify-content-center'}>
                        <span className={'fs-21'}>{channelInfo.nickname}</span>
                        <span className={'fs-13 mt-2'} style={{color: '#aaa !important'}}>구독자 {channelInfo.subscribeCount}명</span>
                    </div>
                </div>
                <div className={'mr-10'}>
                    {
                        isSubscribe ?
                            <Button onClick={onSubscribeChannel} variant="contained" style={{backgroundColor: '#303030'}} className={'pl-20 pr-20'}>
                                구독중
                            </Button> :
                            <Button onClick={onSubscribeChannel} variant="contained" style={{backgroundColor: 'red'}} className={'pl-30 pr-30'}>
                                구독
                            </Button>
                    }
                </div>
            </div>
            <div className={'content-block'}>
                {
                    videoList.map((v) =>
                        <ContentItem content={v} key={v.id} onClick={() => history.push('/view/' + v.id)}/>
                    )
                }
            </div>
        </div>
    )
}

export default ChannelPage;