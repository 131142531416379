/**
 * App Routes
 */
import React from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import RctAppLayout from 'Components/RctAppLayout';
import VodUpload from "./vods";
import Setting from './setting';
import ChannelInfo from './ChannelInfo';
import Comments from './comments'


function ChannelRoute(props){
    const { match } = props;
    return (
        <RctAppLayout>
            <Route path={`${match.url}/vods`} component={VodUpload} />
            <Route path={`${match.url}/setting`} component={Setting} />
            <Route path={`${match.url}/:channelId`} component={ChannelInfo} />
            <Route path={`${match.url}/comments`} component={Comments} />
        </RctAppLayout>
    );
}

export default withRouter(ChannelRoute);
