import React, {} from 'react';
import {useParams} from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import ChannelPage from './ChannelPage'


const ChannelInfo = () => {
    const channelId = useParams().channelId;
    if(uuidValidate(channelId)) {
        return <ChannelPage channelId={channelId} />
    } else {
        return null;
    }
}

export default ChannelInfo;