import React, {useEffect, useState} from 'react';
import RctAppLayout from 'Components/RctAppLayout';
import {
    Player, ControlBar, ReplayControl,
    ForwardControl, CurrentTimeDisplay,
    TimeDivider, PlaybackRateMenuButton, VolumeMenuButton, BigPlayButton
} from 'video-react';
import {useParams} from 'react-router-dom';
import {Scrollbars} from 'react-custom-scrollbars';
import {IconButton, Tooltip, LinearProgress, Button} from "@material-ui/core";
import ThumbUpIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownIcon from "@material-ui/icons/ThumbDownAlt";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Avatar from "Components/Widgets/Avatar";
import ChatComponent from './components/ChatComponent';
import VodComment from './components/VodComment';
import {useDispatch, useSelector} from 'react-redux';
import {collapsedSidebarAction} from 'Store/Actions';
import * as Apis from 'Api';
import moment from "moment";
import {NotificationManager} from "react-notifications";
//import FlvPlayer from "./FlvPlayer";
import FlvPlayer from "./FlvPlayerNode";

const View = () => {
    const dispatch = useDispatch();
    const videoId = useParams().videoId;
    const [videoType, setVideoType] = useState('');
    const [videoName, setVideoName] = useState('');
    const [videoPath, setVideoPath] = useState('');
    const [videoThumbnail, setVideoThumbnail] = useState();
    const [videoTags, setVideoTags] = useState([]);
    const [videoVisit, setVideoVisit] = useState(0);
    const [videoDesc, setVideoDesc] = useState('');
    const [like, setLike] = useState(0);
    const [dislike, setDislike] = useState(0);
    const [videoDate, setVideoDate] = useState();
    const [videoUserInfo, setVideoUserInfo] = useState({});
    const [isSubscribe, setIsSubscribe] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(collapsedSidebarAction(true));
        getData();
    }, []);

    const getData = () => {
        Apis.getVideoInfo(videoId).then((resp) => {
            setVideoType(resp.videoType);
            setVideoName(resp.videoName);
            setVideoPath(resp.videoPath);
            setVideoThumbnail(resp.videoThumbnail);
            setVideoTags(resp.videoTags ? resp.videoTags.split(',') : []);
            setVideoVisit(resp.videoVisit);
            setVideoDesc(resp.videoDesc);
            setLike(resp.videoLike);
            setDislike(resp.videoDislike);
            setVideoDate(resp.videoDate);
            setVideoUserInfo(resp.videoUserInfo);
            setIsSubscribe(resp.isSubscribe);
            setLoading(false);
        }).catch((e) => {
            console.log(e)

        });
    }

    const onLikeVideo = (likeType) => {
        Apis.setVideoLike(videoId, likeType).then((resp) => {
            setLike(resp.like);
            setDislike(resp.dislike);
        }).catch((e) => {
            console.error(e);
        });
    }

    const onSubscribeChannel = () => {
        Apis.setSubscribe(videoUserInfo.id).then((resp) => {
            setIsSubscribe(!isSubscribe);
        }).catch((e) => {
            console.error(e);
        });
    }

    const onAddPlaylist = () => {
        Apis.addPlaylist(videoId).then((resp) => {
            NotificationManager.success('나중에 볼 동영상에 추가되였습니다.');
        }).catch((e) => {
            console.log(e);
        });
    }

    if (loading) {
        return <LinearProgress/>
    }

    return (
        <RctAppLayout>
            <div className={'view-container'}>
                <Scrollbars
                    className="video-wrapper"
                    autoHide
                    autoHideDuration={100}
                >
                    <div className={'video-block'}>
                        {
                            (videoType === 'live' && videoPath.length > 0) ?
                                <FlvPlayer
                                    src={videoPath}
                                /> :
                                <Player
                                    poster={videoThumbnail ? videoThumbnail : `${process.env.PUBLIC_URL}/assets/images/img/no-image.png`}
                                    autoPlay playsinline
                                >
                                    <source src={videoPath}/>
                                    <BigPlayButton position="center"/>
                                    <ControlBar>
                                        <ReplayControl seconds={10} order={1.1}/>
                                        <ForwardControl seconds={30} order={1.2}/>
                                        <CurrentTimeDisplay order={4.1}/>
                                        <TimeDivider order={4.2}/>
                                        <PlaybackRateMenuButton
                                            rates={[4, 2, 1, 0.5, 0.1]}
                                            order={7.1}
                                        />
                                        <VolumeMenuButton/>
                                    </ControlBar>
                                </Player>
                        }
                        <div className={'video-meta-block'}>
                            <div className={'video-tags'}>
                                {
                                    videoTags.map((v) => <a key={v}>#{v}</a>)
                                }
                            </div>
                            <div className={'video-name'}>{videoName}</div>
                            <div className={'video-statistics'}>
                                <div>조회수 {videoVisit}회 • {moment(videoDate).format('YYYY.MM.DD')}</div>
                                <div className={'video-thumb-block'}>
                                    <Tooltip title="이 영상이 마음에 듭니다." placement="bottom" className={'mr-2'}>
                                        <div className={'cursor-pointer'}>
                                            <IconButton aria-label="delete" size="small" onClick={() => onLikeVideo(true)}>
                                                <ThumbUpIcon/>
                                            </IconButton>
                                            <span className={'ml-1 mr-10'}>{like}</span>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title="이 영상이 마음에 들지 않습니다." placement="bottom">
                                        <div className={'cursor-pointer'}>
                                            <IconButton aria-label="delete" size="small" onClick={() => onLikeVideo(false)}>
                                                <ThumbDownIcon/>
                                            </IconButton>
                                            <span className={'ml-1 mr-20'}>{dislike}</span>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title="나중에 볼 동영상에 저장" placement="bottom">
                                        <div className={'cursor-pointer'}>
                                            <IconButton aria-label="delete" size="small" onClick={() => onAddPlaylist()}>
                                                <PlaylistAddIcon/>
                                            </IconButton>
                                            <span className={'ml-0'}> </span>
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={'video-user-block'}>
                                <div className={'d-flex flex-grow-1'}>
                                    <div className={'video-user-avatar'}>
                                        <Avatar src={videoUserInfo.profile} name={videoUserInfo.nickname}/>
                                    </div>
                                    <div className={'video-user-info'}>
                                        <div className={'video-user-name mt-2'}>{videoUserInfo.nickname}</div>
                                        <div className={'video-desc'}>
                                            {videoDesc}
                                        </div>
                                    </div>
                                </div>
                                <div className={'mr-10'}>
                                    {
                                        isSubscribe ?
                                            <Button onClick={onSubscribeChannel} variant="contained" style={{backgroundColor: '#303030'}} className={'pl-20 pr-20'}>
                                                구독중
                                            </Button> :
                                            <Button onClick={onSubscribeChannel} variant="contained" style={{backgroundColor: 'red'}} className={'pl-30 pr-30'}>
                                                구독
                                            </Button>
                                    }
                                </div>
                            </div>
                            {
                                videoType !== 'live' && <VodComment vodId={videoId}/>
                            }
                        </div>
                    </div>
                </Scrollbars>
                {
                    videoType === 'live' && <ChatComponent liveId={videoId}/>
                }
                {/*<div className={'vod-list-block'}>*/}

                {/*</div>*/}
            </div>
        </RctAppLayout>
    )
};

export default View;