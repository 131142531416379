import React, {useState, useEffect} from "react";
import {Button, IconButton, Menu, MenuItem, Tooltip} from '@material-ui/core';
import {Input} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import SiteUserBlock from "./SiteUserBlock";
import {darkModeAction} from 'Store/Actions';


const SiteHeader = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const authUser = useSelector(state => state.authUser);
    const isLogin = authUser.isLogin;
    const settings = useSelector(state => state.settings);
    const [searchText, setSearchText] = useState('');
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const darkModeHandler = (isTrue) => {
        if (isTrue) {
            document.body.classList.add("dark-mode");
        } else {
            document.body.classList.remove("dark-mode");
        }
        dispatch(darkModeAction(isTrue));
    }

    return (
        <div className={'site-header-content'}>
            <a className={'site-header-left'} href={'/'}>
                {
                    !settings.darkMode ?
                        <img src={`${process.env.PUBLIC_URL}/assets/images/img/logo-light.png`} className="site-header-logo" alt="site-logo"/> :
                        <img src={`${process.env.PUBLIC_URL}/assets/images/img/logo-dark.png`} className="site-header-logo" alt="site-logo"/>
                }
            </a>
            <div className={'site-header-right'}>
                {
                    !isLogin ?
                        <div className={'ml-2'}>
                            <Button className={'site-header-button'} onClick={() => history.push('/signin')}>LOGIN</Button>
                            <Button className={'site-header-button'} onClick={() => history.push('/signup')}>JOIN</Button>
                        </div> :
                        <div className={'d-flex flex-row'}>
                            <div>
                                <a className="live-icon" onClick={(event) => setMenuAnchorEl(event.currentTarget)}>
                                    <img
                                        src={
                                            !settings.darkMode ?
                                                require('Assets/img/icon_live.png').default :
                                                require('Assets/img/icon_live_dark.png').default
                                        }
                                        className={'mb-1'}
                                        width={25}
                                    />
                                </a>
                                <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={() => setMenuAnchorEl(null)} style={{top: 30}}>
                                    <MenuItem onClick={() => history.push(`/channel/vods?action=upload`)}>동영상 업로드</MenuItem>
                                    <MenuItem onClick={() => history.push(`/live`)}>실시간 라이브 시작</MenuItem>
                                </Menu>
                            </div>
                            <SiteUserBlock/>
                        </div>
                }
                <div className="site-header-search">
                    <Input
                        type="search"
                        placeholder="검색어를 입력하세요"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyPress={null}
                    />
                </div>
                <Tooltip title={'테마변경'}>
                    <IconButton className={'ml-2 p-1'} onClick={() => darkModeHandler(!settings.darkMode)}>
                        {
                            !settings.darkMode ?
                                <i className={'zmdi zmdi-brightness-5 fs-15'}/> :
                                <i className="zmdi zmdi-brightness-3 fs-15"/>
                        }
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    )
}

export default SiteHeader;