import React from 'react';
import Avatar from "@material-ui/core/Avatar";

const MyAvatar = (props) => {
    if(!props.name || props.src) {
        return <Avatar {...props} style={{ backgroundColor: '#303030', width: props.size, height: props.size, ...props.style,}}/>
    } else {
        return <Avatar {...props} style={{backgroundColor: '#303030', width: props.size, height: props.size, ...props.style,}}>
            <span style={{fontSize: props.size / 3}}>{props.name.substr(0, 2).toUpperCase()}</span>
        </Avatar>
    }
}

MyAvatar.defaultProps = {
    size: 40
}

export default MyAvatar;
