import React, {useEffect, useState, useRef} from 'react';
import {Helmet} from "react-helmet";
import {useDispatch} from "react-redux";
import {FormControlLabel, Checkbox} from '@material-ui/core';
import NotificationManager from "react-notifications";
import Avatar from "Components/Widgets/Avatar";
import {useLocation, useHistory} from "react-router-dom";
import moment from 'moment';
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';
import PaginationComponent from "Components/PaginationComponent";
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';
import {collapsedSidebarAction} from "Store/Actions";
import * as Apis from 'Api';
import VodCommentReply from 'Routes/view/components/VodCommentReply';

const ReplyElement = ()=>{
    return(
        <div style={{width:'50%'}}>
            <input name='relay'></input>
            <div style={{paddingRight:'0px'}}>
                <a>취소</a>
                <a>확인</a>
            </div>

        </div>
        
    )

}

const Comments = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const searchParams = new URLSearchParams(useLocation().search);
    const [loading, setLoading] = useState(false);
    
    const [vodList, setVodList] = useState([]);
    const [CommentList, setCommentList] = useState([]);
    const [ShowReply, setShowReply] = useState(false)

    var inital = true
    useEffect(() => {
        getData();
        dispatch(collapsedSidebarAction(false));
        
    }, []);


    const getData = () => {
        
        Apis.getMyVodList(1).then((resp_vods) => {
            setCommentList(resp_vods)
            
        }).catch((exx) => {
            console.log(exx)
        });
        
        
    }

    const onClickRely = (state) =>{
        setShowReply(state)
    }

    
    

   

    return (
        <div className={'vod-container p-10'}>
            <Helmet>
                <title>동영상 코멘트</title>
                <meta name="channel comment" content="vod comment"/>
            </Helmet>
            <PageTitleBar title={"동영상 코멘트"}/>
            <RctCollapsibleCard fullBlock>
                <div className="table-responsive">
                    
                    <table className="table table-middle table-hover mb-0">
                        <thead>
                        <tr className={'text-center'}>
                            
                        </tr>
                        </thead>
                        <tbody>
                        {
                            CommentList.map((comment) => (
                                <VodCommentReply vodId={comment.id} thumbnail={comment.thumbnail}/>     
                                    
                            ))
                        }

                        </tbody>
                        <tfoot className="border-top">
                        <tr>
                            <td colSpan="100%">
                                {/*<PaginationComponent*/}
                                {/*    totalItems={200}*/}
                                {/*    pageSize={10}*/}
                                {/*    onSelect={() => null}*/}
                                {/*    maxPaginationNumbers={7}*/}
                                {/*    activePage={10}*/}
                                {/*/>*/}
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
                {loading &&
                <RctSectionLoader/>
                }
            </RctCollapsibleCard>
            
        </div>
    )
}

export default Comments;