import React, {useState, useEffect} from "react";
import {NavLink, useHistory} from 'react-router-dom';
import SiteSideChat from "./SiteSideChat";
import {useSelector} from "react-redux";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const SiteSidebar = () => {
    const menuList = [
        {id: 'home', icon: 'home.png', darkIcon: 'home_dark.png', name: '홈', path: '/site'},
        {id: 'bookmark', icon: 'bookmark.png', darkIcon: 'bookmark_dark.png', name: '즐겨찾기', path: '/'},
        {id: 'live', icon: 'live.png', darkIcon: 'live_dark.png', name: '라이브방송', path: '/live'},
        {id: 'news', icon: 'news.png', darkIcon: 'news_dark.png', name: '뉴스', path: 'https://bloomingbit.io/news'},
        {id: 'chart', icon: 'chart.png', darkIcon: 'chart_dark.png', name: '시세챠트', path: '/site/coin-chart'},
        {id: 'analyse', icon: 'analyse.png', darkIcon: 'analyse_dark.png', name: '분석', path: '/'},
        {id: 'community', icon: 'community.png', darkIcon: 'community_dark.png', name: '커뮤니티', path: '/site/community'},
        {id: 'present', icon: 'present.png', darkIcon: 'present_dark.png', name: '선물/마진', path: '/'},
        {
            id: 'ai', icon: 'ai.png', darkIcon: 'ai_dark.png', name: 'AI 오토', path: '/',
            child: [
                {name: '현물오토', path: '/'},
                {name: '선물오토', path: '/'},
                {name: '시세차익오토', path: '/'},
            ]
        },
        {id: 'coin', icon: 'coin.png', darkIcon: 'coin_dark.png', name: '코인', path: '/'},
        {id: 'exchange', icon: 'exchange.png', darkIcon: 'exchange_dark.png', name: '거래소', path: '/'},
        {id: 'notice', icon: 'notice.png', darkIcon: 'notice_dark.png', name: '공지사항', path: '/'},
    ];

    const chatIdList = [
        {chatId: 'sport-chat', chatTitle: '라이브채팅'}
        
    ]

    const history = useHistory();
    const settings = useSelector(state => state.settings);
    const [chatIndex, setChatIndex] = useState(0);
    const [openMenuId, setOpenMenuId] = useState(null);

    const onClickMenu = (item) => {
        setOpenMenuId(item.id !== openMenuId ? item.id : null);
        if (!item.child) {
            history.push(item.path);
        }

    }

    const renderMenuItem = (item) => {
        return (
            <div key={item.id}>
                <div className={'site-sidebar-item'}>
                    <div onClick={() => onClickMenu(item)} className={'site-sidebar-item-link'}>
                        <img src={
                            !settings.darkMode ?
                                `${process.env.PUBLIC_URL}/assets/images/sidebar/${item.icon}` :
                                `${process.env.PUBLIC_URL}/assets/images/sidebar/${item.darkIcon}`
                        } alt=''/>
                        <span>{item.name}</span>
                    </div>
                    {
                        item.child && (openMenuId !== item.id ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>)
                    }
                </div>
                {
                    openMenuId === item.id && item.child &&
                    item.child.map((v) => (
                        <NavLink key={v.name} to={v.path} className={'site-sidebar-child'}>{v.name}</NavLink>
                    ))
                }
            </div>
        )
    }

    return (
        <div className={'site-sidebar-content'}>
            <div className={'site-sidebar-menu'}>
                {
                    menuList.map((menu) => renderMenuItem(menu))
                }
            </div>
            <div className={'m-20'}>
                <div className={'site-side-chat-container'}>
                    <div className={'d-flex flex-row'}>
                        {
                            chatIdList.map((v, i) => (
                                <div className={'site-side-chat-header ' + (chatIndex === i ? "active" : "")} key={v.chatId} onClick={() => setChatIndex(i)}>
                                    {v.chatTitle}
                                </div>
                            ))
                        }
                    </div>
                    {
                        chatIdList.filter((v, i) => i === chatIndex).map((v) => (
                            <SiteSideChat key={v.chatId} chatId={v.chatId}/>
                        ))
                    }
                </div>

            </div>
            <div className={'mt-40'}>
                <NavLink to={''}>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/sidebar/android.png`} alt='' className={'site-sidebar-appdown'}/>
                </NavLink>
                <NavLink to={''}>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/sidebar/apple.png`} alt='' className={'site-sidebar-appdown'}/>
                </NavLink>
            </div>
        </div>
    )
}

export default SiteSidebar;