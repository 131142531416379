import axios from 'axios';
import { Cookies } from 'react-cookie';

const cookie = new Cookies();

function getApiHost()
{
    let hostname = window.location.hostname;
    if (
        hostname === 'localhost' ||
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(hostname)
    )
    {
        hostname = hostname + ':5000';
    }
    hostname = "youtube.mama-hot.com"
    return window.location.protocol + '//' + hostname;
}

export const apiHost = getApiHost();

export const apiAddress = apiHost + '/api/';

export const wsChatAddress = apiHost + '/chats/';

export const accessToken = () => cookie.get("access_token");

const instance = axios.create({
    baseURL: apiAddress,
    timeout: 40000
});

instance.interceptors.request.use(function (config) {
    const token = cookie.get("access_token");
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

instance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401 && error.request.responseURL.indexOf('api/users/login') === -1) {
        // logout status
        cookie.remove('user_info', {path: '/'});
        cookie.remove('access_token', {path: '/'});
        window.location.href = '/signin';
    }
    throw error;
});

export function proxyGetUrl(getUrl) {
    const url = '/util/proxy?url=' + getUrl;
    return instance.get(url).then((response) => response.data);
}

export function login(username, password) {
    const url = '/users/login';
    const req = {
        username,
        password,
        device_type: 'web'
    };
    return instance.post(url, req).then((response) => response.data);
}

export function logintoken(token) {
    const url = '/userlogintoken';
    const req = {
        token
    };
    return instance.post(url, req).then((response) => response.data);
}

export function signup(username, password) {
    const url = '/users';
    const req = {
        username,
        password
    };
    return instance.post(url, req).then((response) => response.data);
}

export function logout() {
    const url = '/users/logout';
    return instance.get(url).then((response) => response.data);
}

export function getUserInfo() {
    const url = '/users/info';
    return instance.get(url).then((response) => response.data);
}

export function setUserInfo(nickname, profile) {
    const url = '/users/info';
    const formData = new FormData();
    formData.append('nickname', nickname);
    if(profile) {
        formData.append('profile', profile);
    }
    return instance.post(url, formData).then((response) => response.data);
}

/*
** user-live-infos functions
 */
export function getUserLiveInfo() {
    const url = '/user-live-infos/detail';
    return instance.get(url).then((response) => response.data);
}

export function updateUserLiveThumbnail(data) {
    const url = '/user-live-infos/thumbnail';
    return instance.post(url, data).then((response) => response.data);
}

export function updateUserLiveInfo(data) {
    const url = '/user-live-infos/update';
    return instance.post(url, data).then((response) => response.data);
}

export function startLive() {
    const url = '/user-live-infos/start-live';
    return instance.get(url).then((response) => response.data);
}

export function stopLive() {
    const url = '/user-live-infos/stop-live';
    return instance.get(url).then((response) => response.data);
}

/*
** categories functions
 */
export function getCategories() {
    const url = '/categories';
    return instance.get(url).then((response) => response.data);
}

/*
** Get video list
 */
export function getVideoList(searchText) {
    const url = '/videos/list' + (searchText ? `?search=${searchText}` : '');
    return instance.get(url).then((response) => response.data);
}

export function getVideoInfo(id) {
    const url = '/videos/' + id;
    return instance.get(url).then((response) => response.data);
}

export function getChannelVideos(channelId) {
    const url = '/videos/channel-list?channelId=' + channelId;
    return instance.get(url).then((response) => response.data);
}

/*
** vods functions
 */
export function uploadVods(data, onProgress) {
    const url = '/vods/vod-upload';
    const config = {
        timeout: 0,
        headers: {
            'content-type': 'multipart/form-data'
        },
        onUploadProgress: function (progressEvent) {
            let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgress(percent);
        }
    };
    return instance.post(url, data, config).then((response) => response.data);
}

export function updateVodThumbnail(vodId, data) {
    const url = `/vods/${vodId}/thumbnail`;
    return instance.post(url, data).then((response) => response.data);
}

export function updateVodInfo(vodId, data) {
    const url = `/vods/${vodId}/update`;
    return instance.post(url, data).then((response) => response.data);
}

export function getMyVodList(pageNum) {
    const url = `/vods/my-list?page=${pageNum}`;
    return instance.get(url).then((response) => response.data);
}

export function getMyVodInfo(id) {
    const url = `/vods/${id}`;
    return instance.get(url).then((response) => response.data);
}

export function deleteMyVod(id) {
    const url = `/vods/${id}`;
    return instance.delete(url).then((response) => response.data);
}

/*
** vod likes functions
 */
export function setVideoLike(videoId, likeType) {
    const url = '/video-likes';
    return instance.post(url, {videoId, likeType}).then((response) => response.data);
}

export function getVideoLikeList() {
    const url = '/video-likes/like-list';
    return instance.get(url).then((response) => response.data);
}

export function deleteVideoLike(videoId) {
    const url = '/video-likes';
    return instance.post(url, {videoId, likeType: false}).then((response) => response.data);
}

/*
**  vod comment functions
 */

export function getAllVodCommentList(videoId) {
    const url = `/video-comments/list`;
    return instance.get(url).then((response) => response.data);
}


export function getVodCommentList(videoId) {
    const url = `/video-comments/list?video-id=${videoId}`;
    return instance.get(url).then((response) => response.data);
}

export function addVodComment(videoId, parentId, content) {
    const url = `/video-comments`;
    return instance.post(url, {videoId, parentId, content}).then((response) => response.data);
}

export function updateVodComment(id, content) {
    const url = `/video-comments/${id}`;
    return instance.patch(url, {content}).then((response) => response.data);
}

export function deleteVodComment(id) {
    const url = `/video-comments/${id}`;
    return instance.delete(url).then((response) => response.data);
}

/*
**  Vod comment like functions
 */
export function setVodCommentLike(commentId, likeType) {
    const url = '/video-comment-likes';
    return instance.post(url, {commentId, likeType}).then((response) => response.data);
}

/*
**  subscribe functions
 */
export function setSubscribe(channelId) {
    const url = '/subscribes';
    return instance.post(url, {channelId}).then((response) => response.data);
}

export function getSubscribeList() {
    const url = '/subscribes/list';
    return instance.get(url).then((response) => response.data);
}

/**
 * User feed features
 */
export function getUserFeedData() {
    const url = '/user-feeds/data';
    return instance.get(url).then((response) => response.data);
}

export function addPlaylist(videoId) {
    const url = '/user-feeds/playlist';
    return instance.post(url, {videoId}).then((response) => response.data);
}

export function getPlaylist() {
    const url = '/user-feeds/playlist';
    return instance.get(url).then((response) => response.data);
}

export function deletePlaylist(videoId) {
    const url = `/user-feeds/playlist/${videoId}`;
    return instance.delete(url).then((response) => response.data);
}

export function getHistory() {
    const url = '/user-feeds/history';
    return instance.get(url).then((response) => response.data);
}

export function deleteHistory(videoId) {
    const url = `/user-feeds/history/${videoId}`;
    return instance.delete(url).then((response) => response.data);
}


/**
 * community features
 */
export function communityList(page, searchType, search) {
    const url = `/community?page=${page}&searchType=${searchType}&search=${search}`;
    return instance.get(url).then((response) => response.data);
}

export function communityNew(title, content) {
    const url = `/community`;
    return instance.post(url, {title, content}).then((response) => response.data);
}

export function communityVote(id, voteType) {
    const url = `/community/${id}/vote?type=${voteType}`;
    return instance.get(url).then((response) => response.data);
}

export function communityItemInfo(id) {
    const url = `/community/${id}`;
    return instance.get(url).then((response) => response.data);
}

export function communityComment(id, content) {
    const url = `/community/${id}/comment`;
    return instance.post(url, {content}).then((response) => response.data);
}


export function saveCoinInfoAdd(data) {
    const url = `/save-coin-info`;
    return instance.post(url, data).then((response) => response.data);
}
































export default axios.create({
    baseURL: 'https://reactify.theironnetwork.org/data/',
    timeout: 2000
});





